import Request from './Request';
import RequestAxios from './RequestAxios';
import RequestRegion from './RequestRegion';
import RequestRegionAxios from './RequestRegionAxios';

class AuthApi {

  /********* LOGIN AND MFA V2 *****************/
  static loginV2(body) {
    console.warn('API | POST | Login user V2');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v2/user-login/login`, body, global.fbAccessToken, withHttpOnlyCookies);
  }
  static loginMfaV2(userId, body) {
    console.warn('API | POST | MFA user login V2');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v2/user-login/user/${userId}/mfa`, body, global.fbAccessToken, withHttpOnlyCookies);
  }





  


  /********* LOGIN AND MFA V1 *****************/
  static login(body) {
    console.warn('API | POST | Login user');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v1/user-login/login`, body, global.fbAccessToken, withHttpOnlyCookies);
  }
  static mfaLogin(userId, body) {
    console.warn('API | POST | MFA user login');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v1/user-login/user/${userId}/mfa`, body, global.fbAccessToken, withHttpOnlyCookies);
  }
  static signup(region, body) {
    console.warn('API | POST | Signup user');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestAxios.POST_json(`${process.env.authAPI}/v1/user-signup/region/${region}`, body, global.fbAccessToken, withHttpOnlyCookies);
  }


  static refreshAccessToken(userDataRegion, body) {
    console.warn('API | POST | Refresh Access Token');
    let withHttpOnlyCookies = true;
    console.warn(`API | POST | withHttpOnlyCookies: ${withHttpOnlyCookies}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-refresh/refresh`, body, global.gpRefreshToken, withHttpOnlyCookies);
  }

  //DELETE USER
  static userDelete(userDataRegion, userId, body) {
    console.warn('API | POST | Delete user');
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-delete/user/${userId}`, body, global.gpAccessToken);
  }

  
  //AUTH | GOODLE AUTHENTICATOR SETUP
  static authenticatorSetup(userDataRegion, userId, body) {
    console.warn('API | POST | Setup Google Authenticator');
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-auth-factor-authenticator/user/${userId}/setup`, body, global.gpAccessToken);
  }
  //AUTH | GOODLE AUTHENTICATOR SETUP | VERIFY
  static authenticatorSetupVerify(userDataRegion, userId, body) {
    console.warn('API | POST | Setup Google Authenticator | Verify');
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-auth-factor-authenticator/user/${userId}/verify`, body, global.gpAccessToken);
  }

  //AUTH | ADD LOGIN FACTOR
  static addLoginFactor(userDataRegion, userId, body) {
    console.warn('API | POST | Add Login Factor');
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-auth-factors/user/${userId}/add`, body, global.gpAccessToken);
  }
  //AUTH | ADD LOGIN FACTOR
  static removeLoginFactor(userDataRegion, userId, body) {
    console.warn('API | POST | Remove Login Factor');
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-auth-factors/user/${userId}/remove`, body, global.gpAccessToken);
  }
  //AUTH | REINSTATE LOGIN FACTOR
  static reinstateLoginFactor(userDataRegion, userId, body) {
    console.warn('API | POST | Reinstate Login Factor');
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-auth-factors/user/${userId}/reinstate`, body, global.gpAccessToken);
  }
  //AUTH | (UNAUTHENTICATED) REINSTATE LOGIN FACTOR
  static unauthenticatedReinstateLoginFactor(userDataRegion, body) {
    console.warn('API | POST | Reinstate Login Factor');
    return RequestRegionAxios.POST_json(userDataRegion, 'authApi', `/v1/user-auth-factors/emergency-reinstate`, body, global.gpAccessToken);
  }

  
  







  static loginAndLinkAccount(newFirebaseAccessToken, existingUserId, body) {
    console.warn('API | POST | Login and link user account');
    return Request.POST_json(`${process.env.authAPI}/v1/link/${existingUserId}`, body, global.gpAccessToken);
  }

  static loginAndDelete(accessToken) {
    console.log('loginAndDelete(accessToken)');
    console.log(accessToken);
    return Request.POST_json(`${process.env.authAPI}/v1/login/delete`, null, global.gpAccessToken);
  }
  
  static firebaseAuthUpdate(accessToken, body) {
    console.warn('API | POST | Firebase user update');
    return Request.POST_json(`${process.env.authAPI}/v1/firebaseupdate`, body, global.gpAccessToken);
  }
  
  
  



}

export default AuthApi;

