import Request from './Request';

class HealthApi {
  static healthAuthApi() {
    console.warn('API | POST | Auth Api Health Check');
    return Request.POST_json(`${process.env.authAPI}/v1/auth/test/response/basic`, null, global.gpAccessToken);
  }
  static healthUsersApi() {
    console.warn('API | POST | Auth Api Health Check');
    return Request.POST_json(`${process.env.usersAPI}/v1/user/test/response/basic`, null, global.gpAccessToken);
  }


  static healthPlatformServiceApi(body) {
    console.warn('API | POST | Get Platform Health');
    return Request.POST_json(`${process.env.healthAPI}/status/all`, body, null);
  }

  //To be built. This should not use the mobile app endpoint.
  static getAppNotifications(userDataRegion, body) {
    console.warn('API | POST | Get Platform Notification');
    //return RequestRegion.POST_json(userDataRegion, 'appSyncApi', `/v1/app-notifications/all`, body, global.gpAccessToken);
  }


}

export default HealthApi;

