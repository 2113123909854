import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';


const config = {
  /* AUTH CHANNEL CODE
  apiKey: 'AIzaSyAWicfoVwgdEbYvRQr-MsyUwnKV-nDP7A8',
  authDomain: 'react-3bc09.firebaseapp.com',
  databaseURL: 'https://react-3bc09.firebaseio.com',
  projectId: 'react-3bc09',
  storageBucket: 'react-3bc09.appspot.com',
  messagingSenderId: '443109815031',
  */

  // GUARDPOINT CODE
  apiKey: "AIzaSyBdD4-WdDOK9D1aES8wf4zlBFr0pEcLSKg",
  authDomain: "guardpoint-me.firebaseapp.com",
  databaseURL: "https://guardpoint-me.firebaseio.com",
  projectId: "guardpoint-me",
  storageBucket: "guardpoint-me.appspot.com",
  messagingSenderId: "981232026435",
  appId: "1:981232026435:web:188ecb138907c95279e5f6",
  measurementId: "G-4MWHCR93FE"
};

firebase.initializeApp(config);

// export const ref = firebase.database().ref();
export const auth = firebase.auth;
// export const provider = new firebase.auth.FacebookAuthProvider();
//export const providerG = new firebase.auth.GoogleAuthProvider();
//export const providerFB = new firebase.auth.FacebookAuthProvider();

// Required for side-effects
// require('firebase/firestore');
// Initialize Cloud Firestore through Firebase
// export const db = firebase.firestore();


let messaging;
if(firebase.messaging.isSupported()) {
  console.log("Firebase Messaging is Supported");
}


/*
export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: 'http://localhost:3002/account/forgot-password',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios',
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12',
  },
  dynamicLinkDomain: 'http://localhost:3002/account/forgot-password',
};
*/
