import React, { lazy, Suspense, Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

// import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import classNames from 'classnames';


import createBrowserHistory from 'history/createBrowserHistory';
import WindowSizeListener from 'react-window-size-listener';
//const createBrowserHistory = React.lazy(() => import(/* webpackPreload: true */'history/createBrowserHistory'));
//const WindowSizeListener = React.lazy(() => import(/* webpackPreload: true */'react-window-size-listener'));





/*
//Fingerprint used to create a client side encryption key for Redux
import sha512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';
import getBrowserFingerprint from 'get-browser-fingerprint';
const fingerprint = getBrowserFingerprint();
console.log("================== fingerprint ====================");
console.log(fingerprint);


//Needs to be content you can re-create from the client side
let fingerprintSalt = `${fingerprint}:GuaRdpOInt12021:${fingerprint}`;

console.log("================== fingerprintSalt ====================");
console.log(fingerprintSalt);

let hashOfFingerprintSalt = sha512(`${fingerprintSalt}`);
let hashOfFingerprintSaltBase64 = Base64.stringify(hashOfFingerprintSalt);
console.log("================== hashOfFingerprintSaltBase64 (KEY) ====================");
console.log(hashOfFingerprintSaltBase64);


var CryptoJS = require("crypto-js");
var accessToken = {uid: '123123123', businessId: '999999'};
 
// Encrypt
var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(accessToken), hashOfFingerprintSaltBase64).toString();
 

console.log("================== ENCRYPTED DATA (ciphertext) ====================");
console.log(ciphertext);

// Decrypt
var bytes  = CryptoJS.AES.decrypt(ciphertext, hashOfFingerprintSaltBase64);
var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
 
console.log("================== DECRYPTED DATA (decryptedData) ====================");
console.log(decryptedData);

*/
/********************************************************************
 * LOCAL ENCRYPTION KEY
 * 1) create fingerprint: 1004584962 (not stable) as its session based anyways
 * 2) Add Salt to fingerprint
 *    1004584962:GuardPoint12021:1004584962
 * 3) Sha512 Hash (fiha03hr90h20r9fua9wrf08wyhraw9r893wur3wr9awr9f0awerfserf)
 * 4) Encrypt the AccessToken with the HASH as the KEY
 * 5) Store the Encrypted data in Redux
 * 
 * TO RETRIEVE
 * 1) Get the encrypted data from Redux
 * 2) Generate the fingerprint + salt + hash
 * 3) Decrypt the AccessToken
 * 
 * 
 * 
 * 
 * 
 */




// Routing
import Routes from 'Core/Routes';

// CSS
//const componentStyles = React.lazy(() => import(/* webpackPreload: true */'./styles.css'));
//const componentStylesEntireSite = React.lazy(() => import(/* webpackPreload: true */'Components/_CommonStyles/commonStyles.css'));

import componentStyles from './styles.css';
import componentStylesEntireSite from 'Components/_CommonStyles/commonStyles.css';

// Components
/*
import AuthRoute from './authRoute';
import CommonRoute from './CommonRoute';
import AdminRoute from './adminRoute';
import RouteNotFound from './RouteNotFound';
*/


import RouteLoading from './RouteLoading';

const AuthRoute = React.lazy(() => import(/* webpackPreload: true */'./authRoute'));
const CommonRoute = React.lazy(() => import(/* webpackPreload: true */'./CommonRoute'));
const AdminRoute = React.lazy(() => import(/* webpackPreload: true */'./adminRoute'));
const RouteNotFound = React.lazy(() => import(/* webpackPreload: true */'./RouteNotFound'));


// <Route path="/home(/:pathParam1)(/:pathParam2)" component={HomePage} />

// Helpers

// Actions
import { updateWindowSize, socketConnection } from 'Actions';




const browserHistory = createBrowserHistory();

class Root extends React.Component {
  constructor(props) {
    super(props);  
    this.state = {
    };

    this.recordWindowSize = this.recordWindowSize.bind(this);
  }

  componentDidMount() {
    this.props.socketConnection();    //THE PROPER SOCKET CONNECTION
  }
  
  /*
  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate - Root/index");
  }
  */


  recordWindowSize(windowSize) {
    //console.log("recordWindowSize");
    //console.log(windowSize);
    //console.log(JSON.stringify(windowSize));
    this.props.updateWindowSize(windowSize);
  }

  
  render() {
    return (
      <Provider store={this.props.store}>
        <WindowSizeListener onResize={(windowSize) => this.recordWindowSize(windowSize)}>
          <div className={classNames({ pageWrapper: true })}>
            <Suspense fallback={<RouteLoading/>}>
              <Router history={browserHistory}>
                <Switch>

                  {Routes.map((singleRoute) => {
                    const {
                    path, exact, permission, ...otherProps
                    } = singleRoute;

                    if (permission.length === 0) {
                      return (
                        <CommonRoute
                          exact={exact !== false}
                          key={singleRoute.path}
                          path={`/${singleRoute.path}`}
                          {...otherProps}
                        />
                      );
                    } else if (permission.includes('admin')) {
                      return (
                        <AdminRoute
                          exact={exact !== false}
                          key={singleRoute.path}
                          path={`/${singleRoute.path}`}
                          {...otherProps}
                        />
                      );
                    }
                    return (
                      <AuthRoute
                        exact={exact !== false}
                        key={singleRoute.path}
                        path={`/${singleRoute.path}`}
                        {...otherProps}
                      />
                    );
                  })}

                  <RouteNotFound
                    key="routeNotFound"
                  />


                </Switch>
              </Router>
            </Suspense>
          </div>
        </WindowSizeListener>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  updateWindowSize,
  socketConnection,
};


export default connect(null, mapDispatchToProps)(Root); 
//export default compose(connect(mapStateToProps, mapDispatchToProps))(Root);
//export default compose(connect(mapStateToProps, mapDispatchToProps, null, { pure: false }))(Root);    //Try deep packet inspection


/* ASYNC EXAMPLE
getAnime = async (query, variables) => {
  try {
    const response = await axios.post('https://graphql.anilist.co', {
      query,
      variables
    });

    // Log the response so we can look at it in the console
    console.log(response.data)

    // Set the data to the state
    this.setState(() => ({
      isLoaded: true,
      items: response.data.data.Page.media
    }));

  } catch (error) {
    // If there's an error, set the error to the state
    this.setState(() => ({ error }))
  }
}
*/

/*
EXAMPLE AUTH MODULE - should do this via component...
export default (
  <Route path="/" component={App}>
    <IndexRoute component={HomePage} />
    <Route path="/login" component={LogInPage} />
    <Route path="/cats" component={CatsPage}
      onEnter={requireAuth}>
      <Route path="/cats/new" component={NewCatPage} />
      <Route path="/cats/:id" component={CatPage} />
    </Route>
    <Route path="/about" component={AboutPage} />
  </Route>
);

function requireAuth(nextState, replace) {
  if (!sessionStorage.jwt) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}


/*
              <Route exact path="/landing" component={LandingPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/home" component={HomePage} />

              <AuthRoute exact path="/my-account/subscriptions" component={MyAccountSubscriptionsPage} />
              <AuthRoute exact path="/my-account/details" component={MyAccountDetailsPage} />
              <AuthRoute exact path="/my-account" component={MyAccountPage} />


              <Route exact path="/api/users/get" component={TestApi} />

              <AdminRoute exact path="/admin" component={AdminPage} />
              <Route exact path="/" component={HomePage} />
              */
