import Request from './Request';
import RequestAxios from './RequestAxios';
import RequestRegion from './RequestRegion';
import RequestRegionAxios from './RequestRegionAxios';



class UsersApi {

  /////////////////////////////////////
  // REGION SUPPORTED API ENDPOINTS
  /////////////////////////////////////

  // NEW API
  static updateUserData(userDataRegion, userId, body) {
    console.warn(`API | PATCH | Update User Data | Region: ${userDataRegion}`);
    return RequestRegionAxios.PATCH_json(userDataRegion, 'usersApi', `/v1/userdata/${userId}`, body, global.gpAccessToken);
  }

  /*
  static updateUserData(userId, body, ) {
    console.warn(`API | PATCH | Update User Data `);
    return Request.PATCH_json(`${process.env.usersAPI}/v1/userdata/${userId}`, body, global.gpAccessToken);
  }
  */


  // REQUEST AN IDENTITY VERIFICATION
  static userIdentitiesVerify(userDataRegion, userId, body) {
    console.warn(`API | POST | Request an Identity to be Verified | Region: ${userDataRegion}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/verification-requests/user/${userId}/verification/identites`, body, global.gpAccessToken);
  }


  // NEW API
  static createUserIdentity(userDataRegion, userId, body, ) {
    console.warn(`API | POST | Create User Identity | Region: ${userDataRegion}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/identity/user/${userId}/identity`, body, global.gpAccessToken);
  }
  // NEW API
  static deleteUserIdentity(userDataRegion, userId, identityId, body, ) {
    console.warn(`API | DELETE | Delete User Identity | Region: ${userDataRegion}`);
    return RequestRegionAxios.DELETE_json(userDataRegion, 'usersApi', `/v1/identity/user/${userId}/identity/${identityId}`, body, global.gpAccessToken);
  }
   // Update User Identity
   static updateUserIdentity(userDataRegion, userId, identityId, body, ) {
    console.warn(`API | PATCH | Update User Identity | Region: ${userDataRegion}`);
    return RequestRegionAxios.PATCH_json(userDataRegion, 'usersApi', `/v1/identity/user/${userId}/identity/${identityId}`, body, global.gpAccessToken);
  }



  // VERIFY AN IDENTITY ////
  static userIdentitiesVerificationCode(userDataRegion, userId, body, userIdentitiesId, verificationCode) {
    console.warn(`API | GET | Verify an identity (Authenticated) | Region: ${userDataRegion}`);
    return RequestRegionAxios.GET_json(userDataRegion, 'usersApi', `/v1/verifications/user/${userId}/identity/${userIdentitiesId}/verify?id=${verificationCode}`, body, global.gpAccessToken);
  }
  //Unable to use region support (NOTE:::)
  static userIdentitiesVerificationUnauthenticated(userId, userIdentityId, userVerificationCode) {
    console.warn('API | GET | Verify an identity (Unauthenticated)');
    return Request.GET_json(`${process.env.usersAPI}/v1/verifications/user/${userId}/identity/${userIdentityId}/verify?id=${userVerificationCode}`, null, null);
  }




  ////////////////////////////////////

  // ///  USER | NOTIFICATIONS ///// (not in use???)
  static createUserNotificationApp(userDataRegion, userId, body, ) {
    console.warn(`API | POST | Create User Notification App | Region: ${userDataRegion}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/user/${userId}/notifications`, body, global.gpAccessToken);
  }

  // NEW API
  // /// NOTIFICATIONS - CREATE NOTIFICATION DEVICE /////
  static userNotificationIdentityCreate(userDataRegion, userId, body, ) {
    console.warn(`API | POST | Notification Identity Add New | Region: ${userDataRegion}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/identity`, body, global.gpAccessToken);
  }
  // /// NOTIFICATIONS - DELETE NOTIFICATION /////
  static userNotificationIdentityDelete(userDataRegion, userId, userNotificationsIdentitiesId, body, ) {
    console.warn(`API | DELETE | Notification Identity Add New | Region: ${userDataRegion}`);
    return RequestRegionAxios.DELETE_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/identity/${userNotificationsIdentitiesId}`, body, global.gpAccessToken);
  }
  // NEW API
  // /// NOTIFICATIONS - VERIFICATION REQUEST /////
  static userNotificationIdentityVerificationRequest(userDataRegion, userId, userIdentitiesId, body, ) {
    console.warn(`API | POST | Notification Identity Verification Request | Region: ${userDataRegion}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/identity/${userIdentitiesId}/verification/request`, body, global.gpAccessToken);
  }
  // NEW API
  // /// NOTIFICATIONS - VERIFICATION CONFIRMATION /////
  static userNotificationIdentityVerificationConfirmation(userDataRegion, userId, userIdentitiesId, body, ) {
    console.warn(`API | POST | Notification Identity Verification Request | Region: ${userDataRegion}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/identity/${userIdentitiesId}/verification/confirm`, body, global.gpAccessToken);
  }


  

 // // LIST USER INTERACTIONS //    1/1/2020 (For new interaction listing page for users)
 static listUserInteractions(userId) {
  console.warn('API | GET | Verify an identity (Unauthenticated)');
  return RequestAxios.POST_json(`${process.env.usersAPI}/v1/interactions/user/${userId}`, null, null);
}
  

  

 
  // RETRIEVE USER MOBILE IDENTITIES FOR DEMOS ////
  static retrieveMobileUserIdentities(userDataRegion, userId, body, ) {
    console.warn(`API | POST | Retrieve Mobile User Identities | Region: ${userDataRegion}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/demo/user/${userId}/mobileIdentities`, body, global.gpAccessToken);
  }


  // ADD NOTIFICATION METHOD FOR USER //
  static userAddNotificationMethod(userDataRegion, userId, body, ) {
    console.warn(`API | POST | Add Notification Method | Region: ${userDataRegion}`);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/notifications/user/${userId}/notification-method`, body, global.gpAccessToken);
  }



  

  //sync user data
  static syncUserData(userDataRegion, userId, body, ) {
    console.warn('API | POST | syncUserData');
    //return Request.POST_json(`${usersApi}/v1/interactions/user/${userId}/interactions`, body, global.gpAccessToken);
    return RequestRegionAxios.POST_json(userDataRegion, 'usersApi', `/v1/sync/user/${userId}/sync`, body, global.gpAccessToken);
  }

}

export default UsersApi;

