module.exports.Request_json = (method, region, endpoint, url, accessToken, body) => {

  console.log(`region: ${region}, endpoint: ${endpoint}`);

  if (region === 'aus') {
    if (endpoint === 'usersApi') {
      url = `${process.env.usersRegionAusApi}${url}`;
    } else if (endpoint === 'businessApi') {
      url = `${process.env.businessRegionAusApi}${url}`;
    } else if (endpoint === 'paymentsApi') {
      url = `${process.env.paymentsRegionAusApi}${url}`;
    } else if (endpoint === 'authApi') {
      url = `${process.env.authRegionAusApi}${url}`;
    } else if (endpoint === 'connectUsersApi') {
      url = `${process.env.connectUsersRegionAusApi}${url}`;
    } else if (endpoint === 'connectDemoApi') {
      url = `${process.env.connectDemoRegionAusApi}${url}`;
    } else if (endpoint === 'verifyInteractionApi') {
      url = `${process.env.verifyInteractionRegionAusApi}${url}`;
    } else if (endpoint === 'verifyPhoneApi') {
      url = `${process.env.verifyPhoneRegionAusApi}${url}`;
    } else if (endpoint === 'generalApi') {
      url = `${process.env.generalRegionAusApi}${url}`;
    } 

  } else if (region === 'usa') {
    if (endpoint === 'usersApi') {
      url = `${process.env.usersRegionUsaApi}${url}`;
    } else if (endpoint === 'businessApi') {
      url = `${process.env.businessRegionUsaApi}${url}`;
    } else if (endpoint === 'paymentsApi') {
      url = `${process.env.paymentsRegionUsaApi}${url}`;
    } else if (endpoint === 'authApi') {
      url = `${process.env.authRegionUsaApi}${url}`;
    } else if (endpoint === 'connectUsersApi') {
      url = `${process.env.connectUsersRegionUsaApi}${url}`;
    } else if (endpoint === 'connectDemoApi') {
      url = `${process.env.connectDemoRegionUsaApi}${url}`;
    } else if (endpoint === 'verifyInteractionApi') {
      url = `${process.env.verifyInteractionRegionUsaApi}${url}`;
    } else if (endpoint === 'verifyPhoneApi') {
      url = `${process.env.verifyPhoneRegionUsaApi}${url}`;
    } else if (endpoint === 'generalApi') {
      url = `${process.env.generalRegionUsaApi}${url}`;
    } 
  }


  const param = {
    method,
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
    mode: 'cors',
    //credentials: 'include',     //NEW 2020
    body,
  };

  return new Promise(((resolve, reject) => {
    console.log(`Fetch ${url} | method: ${method} | body: ${body}`);
    fetch(url, param)
      .then((response) => {
        if (response.ok === true) {
          console.log(`Fetch Response | Status: ${response.ok}, Code: ${response.status}`);
        } else {
          console.error(`Fetch Response | Status: ${response.ok}, Code: ${response.status}`);
        }
        /* DON'T REJECT RESPONSES - LET THE APP HANDLE THEM
        if (!response.ok || response.status < 200 || response.status >= 300) {
          // reject(Error(response.status));    //removing to see if I can get the error code returned
          // return response.status; // added to try and capture errors
          reject(response.status);    //look to remove this and allow the endpoint to handle the messages
        }
        */
        return response.json();
      })
      .then((json) => {
        console.log(json);
        if (json) {
          resolve(json);
        } else {
          reject(Error('json was undefined'));
        }
      })
      .catch((err) => {
        console.error(`Fetch Error | ${err}`);
        reject(err);
      });
  }));
};

module.exports.GET_json = (region, endpoint, url, accessToken) => module.exports.Request_json('GET', region, endpoint, url, accessToken, null);
module.exports.PATCH_json = (region, endpoint, url, body, accessToken) => module.exports.Request_json('PATCH', region, endpoint, url, accessToken, body);
module.exports.POST_json = (region, endpoint, url, body, accessToken) => module.exports.Request_json('POST', region, endpoint, url, accessToken, body);
module.exports.DELETE_json = (region, endpoint, url, body, accessToken) => module.exports.Request_json('DELETE', region, endpoint, url, accessToken, body);

