/** REPORTING ACTIONS ** */
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';


//Store
import store from 'Store/ConfigureStore';

//Determine api endpoint based on request
import BusinessApi from './api/BusinessApi';
import UsersApi from './api/UsersApi';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_REPORTING = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_REPORTING = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

export const updateReportingDataset = data => ({
  type: 'UPDATE_REPORTING_DATASET',
  data,
});

export const reportingComponentPing = data => ({
  type: 'REPORTING_COMPONENT_PING',
  data,
});

export const awaitingReportingDatasetUpdate = data => ({
  type: 'AWAITING_REPORTING_DATASET_UPDATE',
  data,
});

export const resetReportingDatasetUpdate = data => ({
  type: 'RESET_REPORTING_DATASET_UPDATE',
  data,
});

export const registerReportingComponent = data => ({
  type: 'REGISTER_REPORTING_COMPONENT',
  data,
});

export const unRegisterReportingComponent = data => ({
  type: 'UNREGISTER_REPORTING_COMPONENT',
  data,
});




/////////////////////////////////////////////////////////
// REPORTING
export function reportingComponentRegister(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      console.log('reportingComponentPing | NEW');
      console.log("data");
      console.log(data);

      //Register the component in state.
      dispatch(reportingComponentPing(data));
      //Wait 1 second and request data refreshes

      let waitForRedux = setTimeout(function() {
        console.log('Attempt to refresh data');
        refreshReportingData();
      }, 600);

    }));
  };
}



/////////////////////////////////////////////////////////
// REPORTING | EXECUTE SINGLE CALL
function refreshReportingData() {
  /*****************************************************************
   * NOTE: Currently this is hard coded to look at the primary region. 
   * To be updated to look at the local data region for the business. Reducers.
   *****************************************************************/


  //Check state, is there any datasets that components are watching
  const { businessDatasets } = store.getState().Reporting;
  const { isAuthenticated, apiAccessTokenExpiresOn } = store.getState().Auth;
  let currentTime = Date.now();

  let apiAccessTokenExpiresOnMs = apiAccessTokenExpiresOn * 1000;
  //console.log(`${currentTime} < ${apiAccessTokenExpiresOnMs}`);

  if (isAuthenticated === true && currentTime < apiAccessTokenExpiresOnMs) {

    ///////// BUSINESS DATASETS //////////
    //console.log(`businessDatasets Length: ${businessDatasets.length}`);
    let businessComponentsWatchingDatasets = 0;
    try {
      businessComponentsWatchingDatasets = businessDatasets.length;
    } catch (e) {
      businessComponentsWatchingDatasets = 0;
    }

    if (businessComponentsWatchingDatasets > 0) {
      //console.log("There is business datasets to process");

      for (let x = 0; x < businessDatasets.length; x++) {
        let componentsWatching = businessDatasets[x].componentsWatching;
        let requestNewData = false;
        if (componentsWatching.length > 0) {
          requestNewData = true;
        }

        if (requestNewData === true) {
          let status = businessDatasets[x].status;
          let statusTimeStamp = businessDatasets[x].statusTimeStamp;
          let lastUpdateTime = businessDatasets[x].lastUpdate;
          let updateInterval = businessDatasets[x].updateInterval;
          //Convert timestamp to date object
          let lastUpdateTimeDateObj = new Date(lastUpdateTime);
          //console.log(`lastUpdateTime: ${lastUpdateTime}`);
          //console.log(`updateInterval: ${updateInterval}`);
          //console.log(`lastUpdateTimeDateObj.getTime(): ${lastUpdateTimeDateObj.getTime()}`);
          let updateRequiredTimeDateObj = new Date(lastUpdateTimeDateObj.getTime() + updateInterval*1000);
          //console.log(`updateRequiredTimeDateObj: ${updateRequiredTimeDateObj}`);

          //TODO: Check if any components are currently watching the dataset.


          let datasetUpdateRequest = {
            businessId: businessDatasets[x].businessId,
            businessDataRegion: businessDatasets[x].businessDataRegion,
            datasetName: businessDatasets[x].datasetName,
            reportName: businessDatasets[x].datasetName,
          }

          //console.log(`TO UPDATE?: ${currentTime} > ${updateRequiredTimeDateObj.valueOf()}`);
          if (currentTime > updateRequiredTimeDateObj.valueOf()) {
            if (status !== 'updating') {    //And the statusTimeStamp ISNT TOO OLD....

              //Continue with requesting a data update
              //console.log("we need to update this dataset");

              //UPDATE LOCAL DATASET TO AWAITING DATA UPDATE
              //console.log("awaitingReportingDatasetUpdate");
              store.dispatch(awaitingReportingDatasetUpdate(datasetUpdateRequest));
              //CALL API ENDPOINT
              //console.log("updateLocalReportingDataset");
              store.dispatch(updateLocalReportingDataset({datasetUpdateRequest}));
              

            } else {
              //The dataset is already in an update state.
              //statusTimeStamp + 5 minutes
              let statusTimeDateObj = new Date(statusTimeStamp);
              let futureStatusTimeDateObj = new Date(statusTimeDateObj.getTime() + 180*1000);
          
              //console.log(`RESET?: ${currentTime} > ${futureStatusTimeDateObj.valueOf()}`);

              if (currentTime > futureStatusTimeDateObj.valueOf()) {
                //Reset the record status
                //console.log("Data update must have failed, reset update status.");
                store.dispatch(resetReportingDatasetUpdate(datasetUpdateRequest));
              } else {
                //console.log("This dataset is already awaiting an update.");
              }
              
            }


          }
        } else {
          //console.log("There are no components watching this dataset | No updates required");
        }
      }
    } else {
      //console.log("There is NO business datasets to process");
    }
  } else {
    //console.log(`REPORTING | User is not authenticated`);
  }
}





////// CHECK IF REPORTS ARE TO BE REFRESHED /////
let reportingRefreshTimer = setInterval(function() {
  console.log(`Refresh Reports | Every 60 seconds`);
  
  refreshReportingData();

}, 60000); //10 sec    //30000 = 30 seconds




/////////////////////////////////////////////////////////
// REPORT DATA UPDATE FUNCTION (CALL API)
export function updateLocalReportingDataset(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        datasetUpdateRequest = {},
      } = body;

      let businessId = '';
      let businessDataRegion = '';
      let reportName = '';

      try {
        businessId = datasetUpdateRequest.businessId;
      } catch (e) {}
      
      try {
        businessDataRegion = datasetUpdateRequest.businessDataRegion;
      } catch (e) {}

      try {
        reportName = datasetUpdateRequest.reportName;
      } catch (e) {}
      
      
      const apiBody = JSON.stringify({
        datasetUpdateRequest,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'updateLocalReportingDataset',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      console.log("API | updateBusinessReportingData");
      BusinessApi.updateBusinessReportingData(businessDataRegion, businessId, reportName, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | RESPONSE | updateBusinessReportingData');
        console.warn(apiMonitoring);
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        console.log(apiResponseData);

        if (apiResponseData.status === true) {
          //dispatch(syncWorkQueueVerificationsData(apiResponseData.data.adminWorkQueue)); // NEW SYNC TRIAL

          console.log("dispatch to updateReportingDataset");
          console.log({
            businessId: businessId,
            reportName: reportName,
            businessDataRegion: businessDataRegion,
            reportData: apiResponseData.data.reportData
          });


          /*
          dispatch(updateReportingDataset({
            businessId: businessId,
            reportName: reportName,
            businessDataRegion: businessDataRegion,
            reportData: apiResponseData.data.reportData
          }))
          */



          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          /* TEMP HIDE
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_REPORTING({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_REPORTING());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_REPORTING({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }
          */


        } else {
          /* TEMP HIDE
          // Toast - error experienced
          dispatch(addGlobalToastMessage_REPORTING({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));
          */

        }
        
        
        console.log("completed api call");
        resolve(apiResponseData);

      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        /*
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        */
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}





export function add(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        type = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        userId: _id, // Not required, just info
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'syncWorkQueue',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AdminWorkApi.syncWorkQueue(apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('API | RESPONSE | syncWorkQueue');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncWorkQueueVerificationsData(apiResponseData.data.adminWorkQueue)); // NEW SYNC TRIAL
  
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_REPORTING({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_REPORTING());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_REPORTING({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_REPORTING({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}








