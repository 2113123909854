/** USER ACTIONS ** */
import { db } from 'Store/client';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import store from 'Store/ConfigureStore';
import AuthApi from './api/AuthApi';
import UsersApi from './api/UsersApi';
import UsersVerificationApi from './api/UsersVerificationApi';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_USER = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});
export const addGlobalToastMessage_USER = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});




export const hardSyncUserData = user => ({
  type: 'HARD_SYNC_USER_DATA',
  user,
});
export const syncUserData = user => ({
  type: 'SYNC_USER_DATA',
  user,
});

export const updateUserAction = userData => ({
  type: 'UPDATE_USER_DATA',
  userData,
});

export const setCommunicationsToken = data => ({
  type: 'SET_COMMUNICATIONS_TOKEN',
  data,
});

export const userIdentitiesVerificationCodeInputError = data => ({
  type: 'USER_IDENTITIES_VERIFICATION_CODE_INPUT_ERROR',
  data,
});

export const userNotificationsIdentitiesVerificationCodeInputError = data => ({
  type: 'USER_NOTIFICATIONS_IDENTITIES_VERIFICATION_CODE_INPUT_ERROR',
  data,
});

export const cancelUserVerificationRedux = data => ({
  type: 'CANCEL_USER_VERIFICATION',
  data,
});


export function cancelUserVerification(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      const { data } = body;

      dispatch(cancelUserVerificationRedux(data));
      resolve(true);

    }));
  };
}




//////////////////////////////////////////////
// SYNC USER DATA
//////////////////////////////////////////////
export function syncUser(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        type = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      console.warn(`apiActivityId: ${apiActivityId}`);


      const apiBody = JSON.stringify({
        userDataRegion
      });


      UsersApi.syncUserData(userDataRegion, _id, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | syncUserData");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          
          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {

            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_USER());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {

            }
          }

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }

        resolve(apiResponseData);
        
        
      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        reject(error);
      });
      
    }));
  };
}




/*
This adds a new Identity for the user.
Each request needs to be processed by the database first,
which will generate a unique ID.
The data is then sync'd with the react-redux state which will
update the screen.
*/
export function addUserIdentities(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        type = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        action: 'insert',
        type,
        userDataRegion
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.createUserIdentity(userDataRegion, _id, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          let toastData = {};
          console.warn("API | RESPONSE | createUserIdentity");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_USER(toastData));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_USER(toastData));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      
    }));
  };
}




/***************************************************
 * UPDATE A USER IDENTITY (THE PRIMARY VALUE)
 ***************************************************/
export function updateUserIdentities(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      let type = '';
      try {
        type = data.type;
      } catch (e) {
        type = '';
      }
      if (type === undefined) {
        type = '';
      }
      let identity = '';
      try {
        identity = data.identity;
      } catch (e) {
        identity = '';
      }
      if (identity === undefined) {
        identity = '';
      }
      let identityId = '';
      try {
        identityId = data.identityId;
      } catch (e) {
        identityId = '';
      }
      if (identityId === undefined) {
        identityId = '';
      }
      
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        type,
        userDataRegion,
        identity,
        identityId,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.updateUserIdentity(userDataRegion, _id, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          let toastData = {};
          console.warn("API | RESPONSE | createUserIdentity");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_USER(toastData));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_USER(toastData));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      
    }));
  };
}






export function removeUserIdentities(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        identity = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      //console.log(identity);

      const identityId = identity.identityId;

      const apiBody = JSON.stringify({
        action: 'remove',
        identityId,
        userDataRegion
      });

     
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.deleteUserIdentity(userDataRegion, _id, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | deleteUserIdentity");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            //dispatch(hardSyncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL    (WEIRD DELETING OF DATA)**Do we need hard sync??
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));


            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });

    }));
  };
}


export const setStateUserProfile = user => ({
  type: 'SET_USER_PROFILE',
  data: user,
});


// NEW (ADD NEW NOTIFICATION IDENTITY - with code lookup)
export function userNotificationsAddNewIdentity(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}


      const userNotificationLookupCode = data.mobileAppLookupCode; // Whole identity object.
      //console.log('userNotificationLookupCode');
      //console.log(userNotificationLookupCode);


      const apiBody = JSON.stringify({
        userNotificationLookupCode,
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'userNotificationsAddNewIdentity',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.userNotificationIdentityCreate(userDataRegion, _id, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | userNotificationIdentityCreate");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));


            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


// NEW (DELETE NOTIFICATION IDENTITY)
export function userNotificationsRemoveIdentity(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}


      const userNotificationsIdentitiesId = data.identityId; // Whole identity object.
      //console.log('userNotificationsIdentitiesId');
      //console.log(userNotificationsIdentitiesId);


      const apiBody = JSON.stringify({
        userNotificationsIdentitiesId,
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'userNotificationsRemoveIdentity',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.userNotificationIdentityDelete(userDataRegion, _id, userNotificationsIdentitiesId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | userNotificationIdentityDelete");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));


            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

// NEW
export function userNotificationsIdentitiesVerifyNow(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      // const userIdentity = data.useridentityIdentity; // Whole identity object.
      const userIdentity = data.identity; // Whole identity object.
      //console.log('userIdentity');
      //console.log(userIdentity);

      const userNotificationsIdentitiesId = userIdentity._id; // id of identity
      const userNotificationsIdentity = userIdentity.identity; // identity


      const apiBody = JSON.stringify({
        userNotificationsIdentitiesId,
        userNotificationsIdentity,
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'userNotificationsIdentitiesVerifyNow',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.userNotificationIdentityVerificationRequest(userDataRegion, _id, userNotificationsIdentitiesId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | userNotificationIdentityVerificationRequest");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

            dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
            resolve(apiResponseData);

          } else {
             // Toast - error experienced
             dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
          }

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

export function userNotificationsIdentitiesCodeVerifyNow(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      //console.log('data');
      //console.log(data);

      const userIdentity = data.identity; // Whole identity object.
      const verificationCode = data.verificationCode; // Whole identity object.
      //console.log('userIdentity');
      //console.log(userIdentity);

      const userNotificationsIdentitiesId = userIdentity._id; // id of identity
      const userNotificationsIdentity = userIdentity.identity; // identity



      const apiBody = JSON.stringify({
        userNotificationsIdentitiesId,
        userNotificationsIdentity,
        verificationCode,
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.userNotificationIdentityVerificationConfirmation(userDataRegion, _id, userNotificationsIdentitiesId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | userNotificationIdentityVerificationConfirmation");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            //Not sure what this one is actually doing? 
            dispatch(userNotificationsIdentitiesVerificationCodeInputError(apiResponseData.user)); // ERRORED RESPONSE FROM API CALL

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}




///////////////////////////////////////////////////
// REQUEST VERIFICATION OF A USER IDENTITY
///////////////////////////////////////////////////
export function userIdentitiesVerifyNow(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}
    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      // const userIdentity = data.useridentityIdentity; // Whole identity object.
      const userIdentity = data.identity; // Whole identity object.
      //console.log('userIdentity');
      //console.log(userIdentity);

      const userIdentitiesId = userIdentity._id; // id of identity
      const userIdentitiesType = userIdentity.type; // email

      const apiBody = JSON.stringify({
        userIdentitiesId,
        userIdentitiesType,
        userDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.userIdentitiesVerify(userDataRegion, _id, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | userIdentitiesVerify");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          dispatch(setCommunicationsToken(apiResponseData.data)); // Returns the business object (sync/merge it all)

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_USER({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_USER());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_USER({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }


          //dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL
          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        
        
        
        try {
          if (apiResponseData.status === true) {
            if (apiResponseData.data.appStatus === true) {
              resolve({
                status: true,
              });
            } else {
              resolve({
                status: false,
                message: apiResponseData.data.appMessageFailure,
              });
            }
          } else {
            resolve({
              status: false,
              message: 'We have experienced an error.',
            });
          }
        } catch (e) {
          console.error('Error while trying to return status from api call.');
        }
        
        
      })
      .catch((error) => {
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}

/*****************************
 * USED IN
 * - User Identity validation (Verify Now)
 */
export function userIdentitiesCodeVerifyNow(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}


    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      //console.log('data');
      //console.log(data);

      const userIdentity = data.identity; // Whole identity object.
      const verificationCode = data.verificationCode; // Whole identity object.
      //console.log('userIdentity');
      //console.log(userIdentity);

      const userIdentitiesId = userIdentity._id; // id of identity
      const userIdentitiesType = userIdentity.type; // email

     
      const apiBody = JSON.stringify({
        userIdentitiesId,
        userIdentitiesType,
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.userIdentitiesVerificationCode(userDataRegion, _id, apiBody, userIdentitiesId, verificationCode, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | userIdentitiesVerificationCode");
          //console.log(apiResponseData);
         
          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            //Need to check this one?
            dispatch(userIdentitiesVerificationCodeInputError(apiResponseData.data)); // ERRORED RESPONSE FROM API CALL
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          
          try {
            if (apiResponseData.status === true) {
              if (apiResponseData.data.appStatus === true) {
                resolve({
                  status: true,
                });
              } else {
                resolve({
                  status: false,
                  message: apiResponseData.data.appMessageFailure,
                });
              }
            } else {
              resolve({
                status: false,
                message: 'We have experienced an error.',
              });
            }
          } catch (e) {
            console.error('Error while trying to return status from api call.');
          }

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


export function userIdentitiesWebsiteVerifyNow(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      //console.log('data');
      //console.log(data);

      const userIdentity = data.identity; // Whole identity object.
      //console.log('userIdentity');
      //console.log(userIdentity);

      const userIdentitiesId = userIdentity._id; // id of identity
      const userIdentitiesType = userIdentity.type; // email


      const apiBody = JSON.stringify({
        userIdentitiesId,
        userIdentitiesType,
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'userIdentitiesWebsiteVerifyNow',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersVerificationApi.userIdentitiesVerificationWebsite(_id, userIdentitiesId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | userIdentitiesVerificationWebsite");
          //console.log(apiResponseData);
          
          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            //Need to check
            dispatch(userIdentitiesVerificationCodeInputError(apiResponseData.data)); // ERRORED RESPONSE FROM API CALL


            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

/*
export function userIdentitiesGenerateWebsiteCode(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      //console.log('data');
      //console.log(data);

      const userIdentity = data.identity; // Whole identity object.
      //console.log('userIdentity');
      //console.log(userIdentity);

      const userIdentitiesId = userIdentity._id; // id of identity
      const userIdentitiesType = userIdentity.type; // e.g. web

      const apiBody = JSON.stringify({
        userIdentitiesId,
        userIdentitiesType,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersVerificationApi.userIdentitiesGenerateCode(_id, userIdentitiesType, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          //console.log('userIdentitiesVerificationCode: RESPONSE - IF SUCCESSFUL - SYNC AND UPDATE STATUS');
          //console.log(apiResponseData);
          /* NOTE
          - A successful response may be received however the action could be a failure.
          - Check the "status" to determine the approporate dispatch.
          *//*
          let transactionStatus = false;
          try {
            transactionStatus = apiResponseData.status;
          } catch (e) {

          }
          if (transactionStatus === true) {
            // dispatch(syncUserData(apiResponseData.data)); // NEW SYNC TRIAL
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
          } else {
            // dispatch(userIdentitiesVerificationCodeInputError(apiResponseData.data)); // ERRORED RESPONSE FROM API CALL
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
          }


          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

*/





////////////////////////////////////////////////////
// VERIFY AN EMAIL IDENTITY
// This is used on an email, calls our domain with the 
// verification code and the identity id. 
// We then call the api endpoint for verification.
// Allows the user to get a clean html response from the
// website.
////////////////////////////////////////////////////

export function userIdentityVerification(body = {}) {
  return (dispatch, getState) => {
    //UNABLE TO SUPPORT REGION - WE DONT KNOW THE SOURCE   
    //This will be an unauthenticated verification. 
    //The request is from an email address, clicking on a link.
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      let userId = '';
      try {
        userId = data.userId;
      } catch (e) {}
      let userIdentityId = '';
      try {
        userIdentityId = data.userIdentityId;
      } catch (e) {}
      let userVerificationCode = '';
      try {
        userVerificationCode = data.userVerificationCode;
      } catch (e) {}
      

 
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'userIdentityVerification',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.userIdentitiesVerificationUnauthenticated(userId, userIdentityId, userVerificationCode)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | userIdentityVerification");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_USER({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_USER());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_USER({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }
          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_USER({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);
      })
      .catch((error) => {
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}


































    







/* DECOM
// NEW (might be decomming this??)
export function userIdentitiesGenerateToken(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      const userIdentity = data.identity; // Whole identity object.
      //console.log('userIdentity');
      //console.log(userIdentity);

      const userIdentitiesId = userIdentity._id; // id of identity
      const userIdentitiesType = userIdentity.type; // email

      const apiBody = JSON.stringify({
        userIdentitiesId,
        userIdentitiesType,
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersVerificationApi.userIdentitiesTokens(_id, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | userIdentitiesTokens");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}
*/









//// Adding a new notification method ////
export function setupNewUserNotificationMethod(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;

    //// GET USER DATA REGION
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}

    
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      const identityId = data.identityId; 

      const apiBody = JSON.stringify({
        identityId,
        userDataRegion
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addNotificationMethod',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      UsersApi.userAddNotificationMethod(userDataRegion, _id, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | userAddNotificationMethod");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncUserData(apiResponseData.data.userResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_USER());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_USER({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_USER({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
          

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}