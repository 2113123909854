import Request from './Request';

class UsersVerificationApi {



  /* DECOM
  static userIdentitiesTokens(userId, body, ) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.usersAPI}/v1/user/${userId}/verification/identites/token/generate`, body, global.gpAccessToken);
  }
  */





  // /// VERIFY A WEBSITE /////
  static userIdentitiesVerificationWebsite(userId, userIdentitiesId, body, ) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.usersAPI}/v1/user/${userId}/identity/${userIdentitiesId}/verify/website`, body, global.gpAccessToken);
  }









  static generateVerificationTokenWebsite(businessId, body, ) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/${businessId}/generate/token/complex`, body, global.gpAccessToken);
  }

  static generateVerificationTokenEmail(body, ) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/generate-email-verification-token`, body, global.gpAccessToken);
  }

  static generateVerificationTokenBusinessAddress(body, ) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/generate-address-verification-token`, body, global.gpAccessToken);
  }

  static businessVerificationCheckWebsite(body, ) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/verify-website`, body, global.gpAccessToken);
  }


}

export default UsersVerificationApi;
