import Request from './Request';
import RequestRegion from './RequestRegion';


class BusinessApi {

  // // PROFILES //// - REGION SUPPORT
  static createBusinessProfile(businessDataRegion, businessId, body) {
    console.warn(`API | POST | Create Business Profile | Region: ${businessDataRegion}`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile`, body, global.gpAccessToken);
  }
  static updateBusinessProfile(businessDataRegion, businessId, profileId, body) {
    console.warn(`API | PATCH | Update Business Profile | Region: ${businessDataRegion}`);
    return RequestRegion.PATCH_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile/${profileId}`, body, global.gpAccessToken);
  }
  static deleteBusinessProfile(businessDataRegion, businessId, profileId, body) {
    console.warn(`API | DELETE | Delete Business Profile | Region: ${businessDataRegion}`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile/${profileId}`, body, global.gpAccessToken);
  }
// // ASSOCIATE PROFILE //// - REGION SUPPORT
  static createBusinessIdentityAssociationToProfile(businessDataRegion, businessId, profileId, identityId, body) {
    console.warn(`API | POST | Associate Identity to Profile | Region: ${businessDataRegion}`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile/${profileId}/associate/${identityId}`, body, global.gpAccessToken);
  }
  static deleteBusinessIdentityAssociationToProfile(businessDataRegion, businessId, profileId, identityId, body) {
    console.warn(`API | POST | Disassociate Identity to Profile | Region: ${businessDataRegion}`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/profiles/business/${businessId}/profile/${profileId}/associate/${identityId}`, body, global.gpAccessToken);
  }


  // // IDENTITIES //// - REGION SUPPORT
  static createBusinessIdentity(businessDataRegion, businessId, body) {
    console.warn(`API | POST | Create Identity | Region: ${businessDataRegion}`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/identities/business/${businessId}/identity`, body, global.gpAccessToken);
  }
  static updateBusinessIdentity(businessDataRegion, businessId, identityId, body) {
    console.warn(`API | PATCH | Update Identity | Region: ${businessDataRegion}`);
    return RequestRegion.PATCH_json(businessDataRegion, 'businessApi', `/v1/identities/business/${businessId}/identity/${identityId}`, body, global.gpAccessToken);
  }
  static deleteBusinessIdentity(businessDataRegion, businessId, identityId, body) {
    console.warn(`API | DELETE | Delete Identity | Region: ${businessDataRegion}`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/identities/business/${businessId}/identity/${identityId}`, body, global.gpAccessToken);
  }


  // // VERIFICATION - REQUESTS ////
  static createBusinessIdentityVerificationRequest(businessId, body) {
    console.warn(`API | POST | Create Identity Verification Request`);
    return Request.POST_json(`${process.env.businessAPI}/v1/verification-requests/business/${businessId}/verification/identities`, body, global.gpAccessToken);
  }

  // // VERIFICATIONS ////
  static createBusinessIdentityVerificationConfirmation(businessDataRegion, businessId, body, businessIdentitiesId, verificationCode) {
    console.warn(`API | GET | Verify an Identity Confirmation | Region: ${businessDataRegion}`);
    return RequestRegion.GET_json(businessDataRegion, 'businessApi', `/v1/verifications/business/${businessId}/identity/${businessIdentitiesId}/verify?id=${verificationCode}`, body, global.gpAccessToken);
  }

  static createBusinessIdentityVerificationConfirmationRemote(businessId, body, businessIdentitiesId) {
    console.warn(`API | POST | Verify an Identity Confirmation | Remote | Verify from system`);
    return Request.POST_json(`${process.env.businessAPI}/v1/verifications/business/${businessId}/identity/${businessIdentitiesId}/remote/verify`, body, global.gpAccessToken);
  }

  static createBusinessIdentityVerificationCode(businessId, identityId, identityType, body) {
    console.warn(`API | POST | Create Identity Verification Code (website)`);
    return Request.POST_json(`${process.env.businessAPI}/v1/verifications/business/${businessId}/identity/${identityId}/generate/${identityType}`, body, global.gpAccessToken);
  }

  static businessIdentitiesVerificationUnauthenticated(businessId, userIdentityId, userVerificationCode) {
    console.warn(`API | GET | Verify an identity (Unauthenticated)`);
    return Request.GET_json(`${process.env.businessAPI}/v1/verifications/business/${businessId}/identity/${userIdentityId}/verify?id=${userVerificationCode}`, null, null);
  }



  // // GENERIC UPDATES //// - REGION SUPPORT
  static updateBusinessData(businessDataRegion, businessId, body) {
    console.warn(`API | PATCH | Update Business Data | Region: ${businessDataRegion}`);
    return RequestRegion.PATCH_json(businessDataRegion, 'businessApi', `/v1/businessdata/${businessId}`, body, global.gpAccessToken);
  }



 // // REPORTING DATA //// - REGION SUPPORT
 static updateBusinessReportingData(businessDataRegion, businessId, reportName, body) {
  console.warn(`API | POST | Update Business Reporting Data | Region: ${businessDataRegion}`);
  return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/reporting/business/${businessId}/reporting/connect/${reportName}`, body, global.gpAccessToken);
}



  // // BUSINESS USERS ////
  static createBusinessUser(businessDataRegion, businessId, userId, body) {
    console.warn(`API | POST | Create Business User`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/administrator`, body, global.gpAccessToken);
  }
  static deleteBusinessUser(businessDataRegion, businessId, userId, administratorId, body) {
    console.warn(`API | DELETE | Delete Business User`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/administrator/${administratorId}`, body, global.gpAccessToken);
  }
  // // BUSINESS USERS (REMOVE CURRENT USER) ////
  static removeCurrentUserFromBusiness(businessDataRegion, businessId, userId, body) {
    console.warn(`API | DELETE | Remove User From Business`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/remove-from-business`, body, global.gpAccessToken);
  }


  
 


  // // BUSINESS AGENTS ////
  static createBusinessAgents(businessDataRegion, businessId, userId, body) {
    console.warn(`API | POST | Create Business Agents`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/agent-actions/business/${businessId}/user/${userId}/add-agents`, body, global.gpAccessToken);
  }
  static deleteBusinessAgent(businessDataRegion, businessId, userId, body) {
    console.warn(`API | DELETE | Delete Business Agents`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/agent-actions/business/${businessId}/user/${userId}/remove-agent`, body, global.gpAccessToken);
  }
  static deleteAllBusinessAgents(businessDataRegion, businessId, userId, body) {
    console.warn(`API | DELETE | Delete All Business Agents`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/agent-actions/business/${businessId}/user/${userId}/remove-agents`, body, global.gpAccessToken);
  }

  static createBusinessAgentSystemIntegration(businessDataRegion, businessId, userId, agentId, body) {
    console.warn(`API | POST | Create Business Agent System Integration`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/agent-actions/business/${businessId}/user/${userId}/agent/${agentId}/integration`, body, global.gpAccessToken);
  }
  static deleteBusinessAgentSystemIntegration(businessDataRegion, businessId, userId, agentId, body) {
    console.warn(`API | DELETE | Delete Business Agent System Integration`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/agent-actions/business/${businessId}/user/${userId}/agent/${agentId}/integration`, body, global.gpAccessToken);
  }



  static requestBusinessTrialAccount(businessDataRegion, businessId, body) {
    console.warn(`API | POST | Request Business Trial Account | Region: ${businessDataRegion}`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/trial-account/business/${businessId}`, body, global.gpAccessToken);
  }


  

  // permissions haven't been migrated as yet.
  static createBusinessUserPermission(businessDataRegion, businessId, userId, administratorId, body) {
    console.warn(`API | POST | Create Business User Permission`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/administrator/${administratorId}/permissions`, body, global.gpAccessToken);
  }
  static deleteBusinessUserPermission(businessDataRegion, businessId, userId, administratorId, body) {
    console.warn(`API | DELETE | Delete Business User Permission`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/administrator/${administratorId}/permissions`, body, global.gpAccessToken);
  }



  // // BUSINESS - USER RELATED ACTIVITIES ON BUSINESS ////
  static userBusinessInvitationResponse(businessDataRegion, userInvitationResponse, businessId, userId, body) {
    console.warn(`API | POST | User Accepts/Declines Business Invitation`);
    let userResponse = 'decline';
    if (userInvitationResponse === 'accept') {
      userResponse = 'accept';
    }
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/user-actions/business/${businessId}/user/${userId}/business-invitation/${userResponse}`, body, global.gpAccessToken);
  }


  // // BUSINESS - CREATE AND DELETE ////
  static createBusiness(businessDataRegion, body) {
    console.warn(`API | POST | Create Business`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/business/region/${businessDataRegion}`, body, global.gpAccessToken);
  }

  static deleteBusiness(businessId, body) {
    console.warn(`API | DELETE | Delete Business`);
    return Request.DELETE_json(`${process.env.businessAPI}/v1/business/${businessId}/remove`, body, global.gpAccessToken);
  }
  static requestImmediateBusinessDeletion(businessId, body) {
    console.warn(`API | DELETE | Request Immediate Business Deletion`);
    return Request.DELETE_json(`${process.env.businessAPI}/v1/business/${businessId}/remove/immediate`, body, global.gpAccessToken);
  }


  // // BUSINESS CONNECT CODES  ////
  // NOTE: connectAPI (not BusinessAPI)
  static customerConnectOutboundPhone(businessDataRegion, businessId, businessProfileId, body) {
    console.warn(`API | POST | Customer Connect Outbound Phone`);
    return RequestRegion.POST_json(businessDataRegion, 'connectUsersApi', `/v1/outbound/phone/business/${businessId}/profile/${businessProfileId}`, body, global.gpAccessToken);
    //return RequestRegion.POST_json(businessDataRegion, 'connectUsersApi', `/v1/business/${businessId}/profile/${businessProfileId}`, body, global.gpAccessToken);
  }
  static createCustomerConnectCode_inPerson(businessDataRegion, businessId, businessProfileId, body) {
    console.warn(`API | POST | Create Business Customer Connect Codes for In-Person meetings`);
    return RequestRegion.POST_json(businessDataRegion, 'connectUsersApi', `/v1/meeting/business/${businessId}/profile/${businessProfileId}`, body, global.gpAccessToken);
  }


 

  // // BUSINESS - CONTACT DETAILS FOR ACCOUNT ////

  static createBusinessContactIdentityToAccount(businessId, businessIdentityId, body) {
    console.warn(`API | POST | Create Business Contacts`);
    return Request.POST_json(`${process.env.businessAPI}/v1/contacts/business/${businessId}/identity/${businessIdentityId}/businesscontact/create`, body, global.gpAccessToken);
  }
  static deleteBusinessContactIdentityFromAccount(businessDataRegion, businessId, identityId, body) {
    console.warn(`API | DELETE | Delete Business Contacts`);
    //return Request.DELETE_json(`${process.env.businessAPI}/v1/contacts/business/${businessId}/identity/${identityId}/businesscontact/delete`, body, global.gpAccessToken);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/contacts/business/${businessId}/identity/${identityId}/businesscontact/delete`, body, global.gpAccessToken);
  }





  // // SYNC BUSINESS DATA  ////
  static syncBusinessData(businessDataRegion, businessId, body) {
    console.warn(`API | POST | Sync Business Data`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/sync/business/${businessId}/sync`, body, global.gpAccessToken);
  }


  // // SYNC BUSINESS REPORTING LOGS  ////
  static syncLogs(businessDataRegion, businessId, body) {
    console.warn(`API | POST | Sync Reporting Log Data`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/reporting/business/${businessId}/logs`, body, global.gpAccessToken);
  }



  

  // // API KEY MANAGEMENT | CREATE KEY  ////
  static createApiKey(businessDataRegion, businessId, body) {
    console.warn(`API | POST | Create API Key`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/access/business/${businessId}/key`, body, global.gpAccessToken);
  }
  // // API KEY MANAGEMENT | REMOVE KEY  ////
  static removeApiKey(businessDataRegion, businessId, keyId, body) {
    console.warn(`API | POST | Remove API Key`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/access/business/${businessId}/key/${keyId}`, body, global.gpAccessToken);
  }
  // // API KEY MANAGEMENT | CREATE KEY ITEM (actual key)  ////
  static createApiKeyItem(businessDataRegion, businessId, keyId, body) {
    console.warn(`API | POST | Create API Key Item`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/access/business/${businessId}/key/${keyId}/key-item`, body, global.gpAccessToken);
  }
  // // API KEY MANAGEMENT | REMOVE KEY ITEM (actual key)  ////
  static removeApiKeyItem(businessDataRegion, businessId, keyId, keyItemId, body) {
    console.warn(`API | POST | Remove API Key Item`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/access/business/${businessId}/key/${keyId}/key-item/${keyItemId}`, body, global.gpAccessToken);
  }
  // // API KEY MANAGEMENT | TOGGLE KEY ITEM STATUS (actual key)  ////
  static toggleApiKeyItemStatus(businessDataRegion, businessId, keyId, keyItemId, status, body) {
    console.warn(`API | POST | Toggle API Key Item Status`);
    let statusUrlParam = 'active';
    if (status === 'active') {
      statusUrlParam = 'active';
    } else {
      statusUrlParam = 'inactive';
    }
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/access/business/${businessId}/key/${keyId}/key-item/${keyItemId}/status/${statusUrlParam}`, body, global.gpAccessToken);
  }
  // // API KEY MANAGEMENT | REVEAL SECRET KEY ITEM (actual key)  ////
  static revealSecretKey(businessDataRegion, businessId, keyId, keyItemId, body) {
    console.warn(`API | POST | Reveal Secret API Key Item`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/access/business/${businessId}/key/${keyId}/key-item/${keyItemId}/reveal`, body, global.gpAccessToken);
  }

  // // API KEY MANAGEMENT | CREATE KEY IP RESTRICTION  ////
  static createApiKeyIpRestriction(businessDataRegion, businessId, keyId, body) {
    console.warn(`API | POST | Create API Key IP Restriction`);
    return RequestRegion.POST_json(businessDataRegion, 'businessApi', `/v1/access/business/${businessId}/key/${keyId}/http-ip-restriction`, body, global.gpAccessToken);
  }
  // // API KEY MANAGEMENT | REMOVE KEY IP RESTRICTION  ////
  static removeApiKeyIpRestriction(businessDataRegion, businessId, keyId, body) {
    console.warn(`API | POST | Remove API Key IP Restriction`);
    return RequestRegion.DELETE_json(businessDataRegion, 'businessApi', `/v1/access/business/${businessId}/key/${keyId}/http-ip-restriction`, body, global.gpAccessToken);
  }

 
  
}


export default BusinessApi;
