import React, { Component } from 'react';
import asyncComponent from "Helpers/AsyncFunc";
const Routes = [
  {
    path: "404-not-found",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/RouteNotFound")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Permission Denied',
    navTitleExpanded: 'Permission Denied',
    contentTitle: 'Permission Denied',

    //META tags
    metaTitle: 'GuardPoint',
    metaDesc: 'Permission Denied',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/PublicHomePageV2")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Home',
    navTitleExpanded: 'Home',
    contentTitle: 'Home',
    

    //META tags
    metaTitle: 'Collaboration.Solutions | Better ways to connect with people',
    metaDesc: 'Enhance customer communications with Guard Point ',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Cyber Identity Protection (Free Membership)',
    ogSitename: '',
    ogDesc: 'Use Guard Point to protect your identity when interacting with business. We fight identity theft, spam and scams targeted at consumers',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/guardpoint-ogImage.png',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "home",
    navPosition: 5,
    component: asyncComponent(() => import("Containers/PublicHomePageV2")),
    permission: [],
    navVisible: false,
    navVisiblePublic: true, 
    displayicon: 'fas fa-home',
    navTitleCollapsed: 'Home',
    navTitleExpanded: 'Home',
    contentTitle: 'Home',

    //META tags
    metaTitle: 'Collaboration.Solutions | Better ways to connect with people',
    metaDesc: 'Enhance customer communications with Guard Point ',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Cyber Identity Protection (Free Membership)',
    ogSitename: '',
    ogDesc: 'Use Guard Point to protect your identity when interacting with business. We fight identity theft, spam and scams targeted at consumers',
    ogType: 'website',
    ogImage: 'https://guardpoint-assets.s3-ap-southeast-2.amazonaws.com/meta/guardpoint-ogImage.png',
    ogURL: 'https://www.guardpoint.com.au',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],


    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  

  
]
export default Routes;


