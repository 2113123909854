import Cookies from 'universal-cookie';
module.exports.Request_json = (method, url, accessToken, body) => {
  const param = {
    method,
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
    mode: 'cors',
    withCredentials: true,
    //credentials: 'include',     //NEW 2020
    body,
  };

  return new Promise(((resolve, reject) => {
    console.log(`Fetch ${url} | method: ${method} | body: ${body}`);
    fetch(url, param)
      .then((response) => {
        if (response.ok === true) {
          console.log(`Fetch Response | Status: ${response.ok}, Code: ${response.status}`);
        } else {
          console.error(`Fetch Response | Status: ${response.ok}, Code: ${response.status}`);
        }
        /* DON'T REJECT RESPONSES - LET THE APP HANDLE THEM
        if (!response.ok || response.status < 200 || response.status >= 300) {
          // reject(Error(response.status));    //removing to see if I can get the error code returned
          // return response.status; // added to try and capture errors
          reject(response.status);    //look to remove this and allow the endpoint to handle the messages
        }
        */
        return response.json();
      })
      .then((json) => {
        console.log(json);

        
        console.log('Attempt to write cookie');
        try {
          const cookies = new Cookies();
          cookies.set('myCat', 'Pacman', { 
            httpOnly: true,
            maxAge: 60 * 60 * 24 * 2, // 2 days
            domain: 'guardpoint.com.au',
            path: "/",
            secure: true,
            sameSite: 'lax',
          });
          console.log(cookies.get('myCat')); // Pacman
        } catch (e) {
          console.log(e);
        }


        if (json) {
          resolve(json);
        } else {
          reject(Error('json was undefined'));
        }
      })
      .catch((err) => {
        console.error(`Fetch Error | ${err}`);
        reject(err);
      });
  }));
};

module.exports.GET_json = (url, accessToken) => module.exports.Request_json('GET', url, accessToken, null);
module.exports.PATCH_json = (url, body, accessToken) => module.exports.Request_json('PATCH', url, accessToken, body);
module.exports.POST_json = (url, body, accessToken) => module.exports.Request_json('POST', url, accessToken, body);
module.exports.DELETE_json = (url, body, accessToken) => module.exports.Request_json('DELETE', url, accessToken, body);

