

/** CORE ACTIONS ** */
import store from 'Store/ConfigureStore';
import _ from 'lodash';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

const uuidv1 = require('uuid/v1');

// Following are used for data updates (generic format for updating)
import UsersApi from './api/UsersApi';
import BusinessApi from './api/BusinessApi';




export const authStatusFalse_CORE = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const reset_state_core = () => ({
  type: 'RESET_STATE_CORE',
});
export const incrementCounter = () => ({
  type: 'INCREMENT_COUNTER',
});








export const addGlobalToastMessage_CORE = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});


export const syncUserData_USER = user => ({
  type: 'SYNC_USER_DATA',
  user,
});

export const syncBusinessData_BUSINESS = businesses => ({
  type: 'SYNC_BUSINESS_DATA',
  businesses,
});

export const dataTransactionTracking_USER = updateRecords => ({
  type: 'SYNC_DATA_TRANSACTION_TRACKING',
  updateRecords,
});

/** *************************************************
**** CORE REDUX DATA LAYER CHANGES *****************
************************************************** */
export const reduxDataUpdateAction_USER = updateRecords => ({
  type: 'REDUX_UPDATE_USER',
  updateRecords,
});
export const reduxDataUpdateAction_BUSINESSIDENTITIES = updateRecords => ({
  type: 'REDUX_UPDATE_BUSINESSIDENTITIES',
  updateRecords,
});
export const reduxDataUpdateAction_CORE = updateRecords => ({
  type: 'REDUX_UPDATE_CORE',
  updateRecords,
});
export const reduxDataUpdateAction_VERIFY = updateRecords => ({
  type: 'REDUX_UPDATE_VERIFY',
  updateRecords,
});
export const reduxDataUpdateAction_ADMINWORK = updateRecords => ({
  type: 'REDUX_UPDATE_ADMINWORK',
  updateRecords,
});


export const reduxDataInsertAction_USER = insertRecords => ({
  type: 'REDUX_INSERT_USER',
  insertRecords,
});
export const reduxDataInsertAction_BUSINESSIDENTITIES = insertRecords => ({
  type: 'REDUX_INSERT_BUSINESSIDENTITIES',
  insertRecords,
});
export const reduxDataInsertAction_CORE = insertRecords => ({
  type: 'REDUX_INSERT_CORE',
  insertRecords,
});










export const resetReduxState = () => ({
  type: 'RESET_STATE',
});
export const reduxDataUpdateAction = updateRecords => ({
  type: 'REDUX_UPDATE',
  updateRecords,
});
export const reduxDataInsertAction = insertRecords => ({
  type: 'REDUX_INSERT',
  insertRecords,
});
export const reduxDataRemoveAction = removeRecords => ({
  type: 'REDUX_REMOVE',
  removeRecords,
});
// //////////////////////////////////////////////////









// /////////////////////////////// END NEW CODE ////////////////////////


export const changeReduxState = () => ({
  type: 'CHANGE_STATE',
});
export const testUpdateReduxState = () => ({
  type: 'TEST_UPDATE',
});
export const test2UpdateReduxState = () => ({
  type: 'TEST_UPDATE_2',
});
export const test2aUpdateReduxState = updateRecords => ({
  type: 'TEST_UPDATE_2a',
  updateRecords,
});
export const test3UpdateReduxState = updateRecords => ({
  type: 'TEST_UPDATE_3',
  updateRecords,
});

export const removeReduxState = removeRecords => ({
  type: 'TEST20_REMOVE_ITEM',
  removeRecords,
});

export const insertReduxState = insertRecords => ({
  type: 'INSERT_ITEM',
  insertRecords,
});







function dotPathDynamicToStatic(primaryObject, providedDotPath, providedIdentifiers, providedValue) {
  /** ****************************************************************
   *
   * INPUT
   * providedDotPath = "test5.nested1.arr3.$.item1";            //The path to the value we want to update
   * providedIdentifiers = [{"item1":"aaaaa"}];                 //How to handle array items
   * providedValue = "fffff";                                   //The value we will update with
   *
   * FUNCTION
   * Take a string path which is the location path of the Redux State.
   * Update this to handle array positions (if they exist) and create a
   * new string path that can be used to update the state.
   *
   * e.g. test5.nested1.arr3.$.desc1 ==> test5.nested1.arr3[0].desc1
   *
   ***************************************************************** */
  let errors = false;
  const dotArr = providedDotPath.split('.');
  console.log('==== start looping through dotArr ====');

  let nextDotPathStr = '';
  let numberOfArrItemsProcessed = 0;
  for (let x = 0; x < dotArr.length; x++) {
    console.log(dotArr[x]);
    if (dotArr[x] === '$') {
      console.log('numberOfArrItemsProcessed');
      console.log(numberOfArrItemsProcessed);
      // We are looking at an array position. We need to find this location.
      const identifierSearch = providedIdentifiers[numberOfArrItemsProcessed];
      let currentTargetValueIndex = null;
      console.log(`identifierSearch: ${JSON.stringify(identifierSearch)}`);
      if (typeof (identifierSearch) === 'object') {
        if (identifierSearch.isArray) {
          // ARRAY
          // for arrays, we are given the index value, if its not <0, use it.
          currentTargetValueIndex = identifierSearch;
        } else {
          // OBJECT
          // for objects, we are searching for the value and returning the index position
          const currentArrayValue = _.get(primaryObject, nextDotPathStr); // dotPath so far with the current STATE
          currentTargetValueIndex = _.findIndex(currentArrayValue, identifierSearch);
        }
        console.log(`current target position: ${currentTargetValueIndex}`);
        if (currentTargetValueIndex < 0) {
          // ERROR - EXIT OUT
          //console.log('ERROR - unable to find index of array item');
          errors = true;
        }
        nextDotPathStr = `${nextDotPathStr}[${currentTargetValueIndex}]`; // create the array position of the target

      } else {
        //console.log('not object or array on $');
        // for values such as strings, boolean, etc

        // If number, it will be an array position
        if (typeof (identifierSearch) === 'number') {
          console.log('type=number');
          currentTargetValueIndex = identifierSearch;
        } else if (typeof (identifierSearch) === 'string') {
          console.log('type=string');
          currentTargetValueIndex = identifierSearch;
        } else if (typeof (identifierSearch) === 'boolean') {
          console.log('type=boolean');
          currentTargetValueIndex = identifierSearch;
        }
        if (typeof (currentTargetValueIndex) === 'number') {
          nextDotPathStr = `${nextDotPathStr}[${currentTargetValueIndex}]`; // create the array position of the target
        } else {
          console.log('Error determining identifer');
        }

      }
      numberOfArrItemsProcessed++; // increase $ identifer search
    } else {
      if (x !== 0) {
        nextDotPathStr += '.'; // prefix .
      }
      nextDotPathStr += dotArr[x];

    }
    console.log(`nextDotPathStr: ${nextDotPathStr}`);

  }
  if (errors) {
    console.log('ERRORS');
    console.log(errors);
    return; // Do not return a value. Function used when pushing directing to an array. In this case, we don't want a value returned.
  }
  const returnObject = {
    dotPath: nextDotPathStr,
    updateValue: providedValue,
  };
  
  console.log('returnObject');
  console.log(returnObject);
  return returnObject;
}




function dotPathDynamicToStaticRemoval(primaryObject, providedDotPath, providedIdentifiers, providedValue) {
  /** ****************************************************************
   *
   * INPUT
   * providedDotPath = "test5.nested1.arr3.$.item1";            //The path to the value we want to update
   * providedIdentifiers = [{"item1":"aaaaa"}];                 //How to handle array items
   * providedValue = "fffff";                                   //The value we will update with
   *
   * FUNCTION
   * Take a string path which is the location path of the Redux State.
   * Update this to handle array positions (if they exist) and create a
   * new string path that can be used to update the state.
   *
   * e.g. test5.nested1.arr3.$.desc1 ==> test5.nested1.arr3[0].desc1
   *
   ***************************************************************** */
  let errors = false;
  const dotArr = providedDotPath.split('.');
  //console.log('==== start looping through dotArr ====');

  let nextDotPathStr = '';
  let numberOfArrItemsProcessed = 0;
  const dotPathPredecessorReferences = [];
  for (let x = 0; x < dotArr.length; x++) {
    //console.log(dotArr[x]);
    if (dotArr[x] === '$') {
      /*
      At this point, we need to know the parent path and the result of this index value.
      This is added to an array (based on the number $ we come across.)
      This is because when we remove an item from an array, we also need to remove
      its element from the array (which means updating with the new value.)
      We need the parent to access and the index value to remove.
      */
      const dotPathPredecessor = nextDotPathStr; // the path so far.


      // We are looking at an array position. We need to find this location.
      const identifierSearch = providedIdentifiers[numberOfArrItemsProcessed];
      let currentTargetValueIndex = null;
      //console.log(`identifierSearch: ${identifierSearch}`);
      if (typeof (identifierSearch) === 'object') {
        if (identifierSearch.isArray) {
          // ARRAY
          // for arrays, we are given the index value, if its not <0, use it.
          currentTargetValueIndex = identifierSearch;
        } else {
          // OBJECT
          // for objects, we are searching for the value and returning the index position
          const currentArrayValue = _.get(primaryObject, nextDotPathStr); // dotPath so far with the current STATE
          currentTargetValueIndex = _.findIndex(currentArrayValue, identifierSearch);
        }
        //console.log(`current target position: ${currentTargetValueIndex}`);
        if (currentTargetValueIndex < 0) {
          // ERROR - EXIT OUT
          //console.log('ERROR - unable to find index of array item');
          errors = true;
        }
        nextDotPathStr = `${nextDotPathStr}[${currentTargetValueIndex}]`; // create the array position of the target

      } else {
        //console.log('not object or array on $');
        // for values such as strings, boolean, etc

        // If number, it will be an array position
        if (typeof (identifierSearch) === 'number') {
          //console.log('type=number');
          currentTargetValueIndex = identifierSearch;
        } else if (typeof (identifierSearch) === 'string') {
          //console.log('type=string');
          currentTargetValueIndex = identifierSearch;
        } else if (typeof (identifierSearch) === 'boolean') {
          //console.log('type=boolean');
          currentTargetValueIndex = identifierSearch;
        }
        if (typeof (currentTargetValueIndex) === 'number') {
          nextDotPathStr = `${nextDotPathStr}[${currentTargetValueIndex}]`; // create the array position of the target
        } else {
          //console.log('Error determining identifer');
        }

      }

      // Add to the predecessor array
      const dotPathPredecessorIndex = currentTargetValueIndex;
      dotPathPredecessorReferences.push({ dotPathPredecessor, dotPathPredecessorIndex });


      numberOfArrItemsProcessed++; // increase $ identifer search
    } else {
      if (x !== 0) {
        nextDotPathStr += '.'; // prefix .
      }
      nextDotPathStr += dotArr[x];

    }
  }
  if (errors) {
    //console.log('ERRORS');
    return; // Do not return a value. Function used when pushing directing to an array. In this case, we don't want a value returned.
  }
  const returnObject = {
    dotPath: nextDotPathStr,
    updateValue: providedValue,
    dotPathPredecessorReferences,
  };
  return returnObject;
} // END REMOVAL FUNCTION




export function RemoveReduxState(body = {}) {
  return (dispatch, getState) => {
    // const {apiAccessToken} = getState().Auth;
    const STATE_CORE = getState().Core; // This is the state we are going to modify
    return new Promise(((resolve, reject) => {
      const {
        recordsForRemoval = [],
      } = body;

      // foreach item in recordsForRemoval, process and add to the removeRecords[]

      //console.log(JSON.stringify(recordsForRemoval));

      let removeRecords = [];
      for (let z = 0; z < recordsForRemoval.length; z++) {
        const dotPathName = recordsForRemoval[z].name;
        const identifiers = recordsForRemoval[z].identifiers;
        const value = recordsForRemoval[z].value;

        //console.log('loop');
        //console.log(dotPathName);
        //console.log(identifiers);
        //console.log(value);


        // need to pass in what STATE this record is trying to access      ******

        removeRecords.push(dotPathDynamicToStaticRemoval(STATE_CORE, dotPathName, identifiers, value));

      }

      // Remove null & undefined
      removeRecords = removeRecords.filter(el => el != null);

      //console.log('--------------------------removeRecords---------------------------');
      //console.log(JSON.stringify(removeRecords));

      dispatch(removeReduxState(removeRecords));

    }));
  };
}


export function InsertIntoRedux(body = {}) {
  return (dispatch, getState) => {
    // const {apiAccessToken} = getState().Auth;
    const STATE_CORE = getState().Core; // This is the state we are going to modify
    return new Promise(((resolve, reject) => {
      const {
        recordsForInsertion = [],
      } = body;

      // foreach item in recordsForUpdate, process and add to the insertRecords[]

      //console.log(JSON.stringify(recordsForInsertion));

      let insertRecords = [];
      for (let z = 0; z < recordsForInsertion.length; z++) {
        const dotPathName = recordsForInsertion[z].name;
        const identifiers = recordsForInsertion[z].identifiers;
        const stateIdentifier = recordsForInsertion[z].stateIdentifier;
        const value = recordsForInsertion[z].value;

        //console.log('loop');
        //console.log(dotPathName);
        //console.log(identifiers);
        //console.log('stateIdentifier');
        //console.log(stateIdentifier);
        /*
        if (typeof(identifiers)!=='object') {
          try {
            identifiers = JSON.parse(identifiers);
          } catch(error) {
            //console.log("ERROR PARSING");
          }

        }
        */
        //console.log(value);


        // need to pass in what STATE this record is trying to access      ******

        insertRecords.push(dotPathDynamicToStatic(STATE_CORE, dotPathName, identifiers, value));

      }

      // Remove null & undefined
      insertRecords = insertRecords.filter(el => el != null);

      //console.log('--------------------------insertRecords---------------------------');
      //console.log(JSON.stringify(insertRecords));

      dispatch(insertReduxState(insertRecords));

    }));
  };
}










/** ******************************************************
***** BACKEND DATA UPDATE *********************************
*****  ******
******************************************************* */
export function apiDataUpdate(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    
    let userDataRegion = '';
    try {
      userDataRegion = getState().User.dataRegion;   //new region testing
    } catch (e) {}
    

    console.warn("============apiDataUpdate=============");
    
    const {
      apiDataObj = {},
    } = body;

    let apiActivityId = '';
    try {
      const { apiUi } = body;
      apiActivityId = apiUi.apiActivityId;
    } catch (e) {

    }
    
    // print out the object for testing
    //console.log(JSON.stringify(apiDataObj));

    /* PROCESS
    1. Determine where this data should be sent for processing. ("state" field)
    2. Send the data to the api endpoint.
    3. Determine if the data need to be resync'd with local state. (Often this isn't the case)
    */

    const stateDataReference = apiDataObj.state;
    const dataUpdatesArray = apiDataObj.data;

    let apiMonitoring = {};

    //console.log(`reduxDataUpdate | State: ${stateDataReference}`);

    let dataTracking = {
      state: apiDataObj.state,
      reference: apiDataObj.data[0].reference,
      identifiers: apiDataObj.data[0].dataSet.identifiers,
      value: apiDataObj.data[0].dataSet.value,
      apiTransactionId: apiDataObj.data[0].dataSet.lastChangeTransactionId,
      apiInprogress: true,
      apiCompleted: false,
      apiErrors: false,
      apiStatus: 'inprogress',
      timestamp: Date.now(),
    };
    //console.log(dataTracking);



    console.warn(`stateDataReference: ${stateDataReference}`);



    if (stateDataReference === 'User') {
      //console.log('API ENDPOINT TARGET: USER');
      const { _id } = getState().User;


      
      let apiBody = JSON.stringify({
        stateDataReference,
        dataUpdatesArray,
        webTransactionApiId: apiDataObj.data[0].dataSet.lastChangeTransactionId,
        userDataRegion,
      });




      // Testing - this would record down the transaction we have sent off to the back end.
      // we can close this off once we receive a response.
      dispatch(dataTransactionTracking_USER(dataTracking));

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'apiDataUpdate',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress


      //console.log(`updateUserData for region: ${userDataRegion}`)

      UsersApi.updateUserData(userDataRegion, _id, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('API | updateUserData | updateUserData');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncUserData_USER(apiResponseData.data.userResult)); 

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_CORE(toastData));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_CORE());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_CORE(toastData));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));


            ////////////////////////////////////////////////////////////////////////////
            let apiTransactionData = {};
            let dataTracking = {};

            let apiTransactionId = '';
            try {
              apiTransactionId = apiResponseData.apiTransaction.webTransactionApiId;
            } catch(e) {}

            try {
              //apiTransactionData = apiResponseData.apiTransactionData;
              //console.log("======================apiTransactionData======================");
              //console.log(JSON.stringify(apiTransactionData));
              let dataTracking = {
                //state: apiTransactionData.state,
                //reference: apiTransactionData.data[0].reference,
                //identifiers: apiTransactionData.data[0].dataSet.identifiers,
                //value: apiTransactionData.data[0].dataSet.value,
                apiTransactionId,
                apiInprogress: false,
                apiCompleted: true,
                apiErrors: false,
                apiStatus: 'completed',
                timestamp: Date.now(),
              };


              // Testing - this would record down the transaction we have sent off to the back end.
              // we can close this off once we receive a response.
              dispatch(dataTransactionTracking_USER(dataTracking));


            } catch (err) {
              //console.log("ERROR accessing data");
              //console.log(err);
            }

            //console.log("dataTracking");
            //console.log(dataTracking);
            ////////////////////////////////////////////////////////////////////////////

  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_CORE({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
            
            ////////////////////////////////////////////////////////////////////////////
            let dataTracking = {
              state: apiDataObj.state,
              reference: apiDataObj.data[0].reference,
              identifiers: apiDataObj.data[0].dataSet.identifiers,
              value: apiDataObj.data[0].dataSet.value,
              apiTransactionId: apiDataObj.data[0].dataSet.lastChangeTransactionId,
              apiInprogress: false,
              apiCompleted: true,
              apiErrors: true,
              apiStatus: 'completed with errors',
              timestamp: Date.now(),
            };
            //console.log(dataTracking);
      
            // Testing - this would record down the transaction we have sent off to the back end.
            // we can close this off once we receive a response.
            dispatch(dataTransactionTracking_USER(dataTracking));
            ////////////////////////////////////////////////////////////////////////////

  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes

        });

    } else if (stateDataReference === 'BusinessIdentities') {


      console.warn(`BusinessIdentities`);



      //console.log('API ENDPOINT TARGET: BUSINESS IDENTITIES');
      const businessId = dataUpdatesArray[0].dataSet.identifiers[0]._id; // Note: dataUpdatesArray[0] <-- from memory, there will be only 1 business on each call at a time.
      
      console.warn(`businessId: ${businessId}`);



      //console.log(`UPDATING ID: ${businessId}`);

      ///////////////////////////////////////////
      // GET THE BUSINESS DATA REGION
      ///////////////////////////////////////////
      const { identities } = getState().BusinessIdentities;

      //console.log("============ identities ================");
      //console.log(identities);

      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        //console.log(identities.businesses[x]['_id']);
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      
      console.warn(`businessDataRegion: ${businessDataRegion}`);

      let apiBody = JSON.stringify({
        stateDataReference,
        dataUpdatesArray,
        webTransactionApiId: apiDataObj.data[0].dataSet.lastChangeTransactionId,
        businessDataRegion,
      });

      

      let dataTracking = {
        state: apiDataObj.state,
        reference: apiDataObj.data[0].reference,
        identifiers: apiDataObj.data[0].dataSet.identifiers,
        value: apiDataObj.data[0].dataSet.value,
        apiTransactionId: apiDataObj.data[0].dataSet.lastChangeTransactionId,
        apiInprogress: true,
        apiCompleted: false,
        apiErrors: false,
        apiStatus: 'inprogress',
        timestamp: Date.now(),
      };
      //console.log(dataTracking);

      // Testing - this would record down the transaction we have sent off to the back end.
      // we can close this off once we receive a response.
      dispatch(dataTransactionTracking_USER(dataTracking));

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      apiMonitoring = {
        id: uuidv1(),
        api: 'CoreApi',
        function: 'apiDataUpdate',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.updateBusinessData(businessDataRegion, businessId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | updateBusinessData');
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          dispatch(syncBusinessData_BUSINESS(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }
              dispatch(addGlobalToastMessage_CORE(toastData));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_CORE());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }
              dispatch(addGlobalToastMessage_CORE(toastData));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

          ////////////////////////////////////////////////////////////////////////////
          let apiTransactionData = {};
          let dataTracking = {};

          let apiTransactionId = '';
          try {
            apiTransactionId = apiResponseData.apiTransaction.webTransactionApiId;
          } catch(e) {}

          try {
            //apiTransactionData = apiResponseData.apiTransactionData;
            //console.log("======================apiTransactionData======================");
            //console.log(JSON.stringify(apiTransactionData));
            let dataTracking = {
              //state: apiTransactionData.state,
              //reference: apiTransactionData.data[0].reference,
              //identifiers: apiTransactionData.data[0].dataSet.identifiers,
              //value: apiTransactionData.data[0].dataSet.value,
              apiTransactionId,
              apiInprogress: false,
              apiCompleted: true,
              apiErrors: false,
              apiStatus: 'completed',
              timestamp: Date.now(),
            };


            // Testing - this would record down the transaction we have sent off to the back end.
            // we can close this off once we receive a response.
            dispatch(dataTransactionTracking_USER(dataTracking));


          } catch (err) {
            //console.log("ERROR accessing data");
            //console.log(err);
          }

          //console.log("dataTracking");
          //console.log(dataTracking);
          ////////////////////////////////////////////////////////////////////////////



        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_CORE({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

          ////////////////////////////////////////////////////////////////////////////
          let dataTracking = {
            state: apiDataObj.state,
            reference: apiDataObj.data[0].reference,
            identifiers: apiDataObj.data[0].dataSet.identifiers,
            value: apiDataObj.data[0].dataSet.value,
            apiTransactionId: apiDataObj.data[0].dataSet.lastChangeTransactionId,
            apiInprogress: false,
            apiCompleted: true,
            apiErrors: true,
            apiStatus: 'completed with errors',
            timestamp: Date.now(),
          };
          //console.log(dataTracking);
    
          // Testing - this would record down the transaction we have sent off to the back end.
          // we can close this off once we receive a response.
          dispatch(dataTransactionTracking_USER(dataTracking));
          ////////////////////////////////////////////////////////////////////////////

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);
      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });


    } else if (stateDataReference === 'AdminWork') {
      




      /* NOT SETUP AS YET
      let apiBody = JSON.stringify({
        stateDataReference,
        dataUpdatesArray,
        webTransactionApiId: apiDataObj.data[0].dataSet.lastChangeTransactionId,
        userDataRegion,
      });




      // Testing - this would record down the transaction we have sent off to the back end.
      // we can close this off once we receive a response.
      dispatch(dataTransactionTracking_USER(dataTracking));

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'apiDataUpdate',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress


      //console.log(`updateUserData for region: ${userDataRegion}`)

      UsersApi.updateUserData(userDataRegion, _id, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn('API | updateUserData | updateUserData');
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          dispatch(syncUserData_USER(apiResponseData.data.userResult)); 

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }
              dispatch(addGlobalToastMessage_CORE(toastData));
            }
          } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_CORE());
                }
              } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }
              dispatch(addGlobalToastMessage_CORE(toastData));
            }
          }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));


          ////////////////////////////////////////////////////////////////////////////
          let apiTransactionData = {};
          let dataTracking = {};

          let apiTransactionId = '';
          try {
            apiTransactionId = apiResponseData.apiTransaction.webTransactionApiId;
          } catch(e) {}

          try {
            //apiTransactionData = apiResponseData.apiTransactionData;
            //console.log("======================apiTransactionData======================");
            //console.log(JSON.stringify(apiTransactionData));
            let dataTracking = {
              //state: apiTransactionData.state,
              //reference: apiTransactionData.data[0].reference,
              //identifiers: apiTransactionData.data[0].dataSet.identifiers,
              //value: apiTransactionData.data[0].dataSet.value,
              apiTransactionId,
              apiInprogress: false,
              apiCompleted: true,
              apiErrors: false,
              apiStatus: 'completed',
              timestamp: Date.now(),
            };


            // Testing - this would record down the transaction we have sent off to the back end.
            // we can close this off once we receive a response.
            dispatch(dataTransactionTracking_USER(dataTracking));


          } catch (err) {
            //console.log("ERROR accessing data");
            //console.log(err);
          }

          //console.log("dataTracking");
          //console.log(dataTracking);
          ////////////////////////////////////////////////////////////////////////////


        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_CORE({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          
          ////////////////////////////////////////////////////////////////////////////
          let dataTracking = {
            state: apiDataObj.state,
            reference: apiDataObj.data[0].reference,
            identifiers: apiDataObj.data[0].dataSet.identifiers,
            value: apiDataObj.data[0].dataSet.value,
            apiTransactionId: apiDataObj.data[0].dataSet.lastChangeTransactionId,
            apiInprogress: false,
            apiCompleted: true,
            apiErrors: true,
            apiStatus: 'completed with errors',
            timestamp: Date.now(),
          };
          //console.log(dataTracking);
    
          // Testing - this would record down the transaction we have sent off to the back end.
          // we can close this off once we receive a response.
          dispatch(dataTransactionTracking_USER(dataTracking));
          ////////////////////////////////////////////////////////////////////////////


        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);

      })
      .catch((error) => {
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes

      });
      */
    
    } else {
      //console.log('API ENDPOINT NOT FOUND');
    }


    return 1;



  };
}


/** ******************************************************
***** REDUX DATA UPDATE *********************************
***** Update any value based on a provided dotPath ******
******************************************************* */
export function reduxDataUpdate(body = {}) {
  return (dispatch, getState) => {

    const {
      recordsForUpdate = '',
    } = body;
    //console.log('reduxDataUpdate');
    //console.log(recordsForUpdate[0].stateIdentifier);
    const stateIdentifier = recordsForUpdate[0].stateIdentifier;
    //console.log(stateIdentifier);
    let STATE = '';
    if (stateIdentifier === 'User') {
      STATE = getState().User;
    } else if (stateIdentifier === 'Core') {
      STATE = getState().Core;
    } else if (stateIdentifier === 'BusinessIdentities') {
      STATE = getState().BusinessIdentities;
    } else if (stateIdentifier === 'Verify') {
      STATE = getState().BusinessIdentities;
    } else if (stateIdentifier === 'AdminWork') {
      STATE = getState().AdminWork;
    }
    //console.log(`reduxDataUpdate | State: ${STATE}`);

    // This is the state we are going to modify

    return new Promise(((resolve, reject) => {
      const {
        recordsForUpdate = [],
      } = body;
      let updateRecords = [];
      for (let z = 0; z < recordsForUpdate.length; z++) {
        const dotPathName = recordsForUpdate[z].name;
        const identifiers = recordsForUpdate[z].identifiers;
        const value = recordsForUpdate[z].value;
        console.log('loop');
        console.log(dotPathName);
        console.log(identifiers);
        console.log(value);
        updateRecords.push(dotPathDynamicToStatic(STATE, dotPathName, identifiers, value));
      }
      updateRecords = updateRecords.filter(el => el != null);
      console.log('--------------------------reduxDataUpdate---------------------------');
      console.log(JSON.stringify(updateRecords));

      // dispatch to the relevant reducer
      if (stateIdentifier === 'User') {
        dispatch(reduxDataUpdateAction_USER(updateRecords));
      } else if (stateIdentifier === 'Core') {
        dispatch(reduxDataUpdateAction_CORE(updateRecords));
      } else if (stateIdentifier === 'BusinessIdentities') {
        dispatch(reduxDataUpdateAction_BUSINESSIDENTITIES(updateRecords));
      } else if (stateIdentifier === 'Verify') {
        dispatch(reduxDataUpdateAction_VERIFY(updateRecords));
      } else if (stateIdentifier === 'AdminWork') {
        dispatch(reduxDataUpdateAction_ADMINWORK(updateRecords));
      }


    }));
  };
}

export function reduxDataInsert(body = {}) {
  return (dispatch, getState) => {

    const {
      recordsForInsertion = '',
    } = body;
    //console.log('reduxDataInsert');
    //console.log(recordsForInsertion[0].stateIdentifier);
    const stateIdentifier = recordsForInsertion[0].stateIdentifier;
    //console.log(stateIdentifier);
    let STATE = '';
    if (stateIdentifier === 'User') {
      STATE = getState().User;
    } else if (stateIdentifier === 'Core') {
      STATE = getState().Core;
    }


    return new Promise(((resolve, reject) => {
      const {
        recordsForInsertion = [],
      } = body;

      // foreach item in recordsForUpdate, process and add to the insertRecords[]

      //console.log(JSON.stringify(recordsForInsertion));

      let insertRecords = [];
      for (let z = 0; z < recordsForInsertion.length; z++) {
        const dotPathName = recordsForInsertion[z].name;
        const identifiers = recordsForInsertion[z].identifiers;
        const stateIdentifier = recordsForInsertion[z].stateIdentifier;
        const value = recordsForInsertion[z].value;

        //console.log('loop');
        //console.log(dotPathName);
        //console.log(identifiers);
        //console.log('stateIdentifier');
        //console.log(stateIdentifier);
        /*
        if (typeof(identifiers)!=='object') {
          try {
            identifiers = JSON.parse(identifiers);
          } catch(error) {
            //console.log("ERROR PARSING");
          }

        }
        */
        //console.log(value);

        insertRecords.push(dotPathDynamicToStatic(STATE, dotPathName, identifiers, value));

      }

      // Remove null & undefined
      insertRecords = insertRecords.filter(el => el != null);

      //console.log('--------------------------insertRecords---------------------------');
      //console.log(JSON.stringify(insertRecords));

      // dispatch to the relevant reducer
      if (stateIdentifier === 'User') {
        dispatch(reduxDataInsertAction_USER(insertRecords));
      } else if (stateIdentifier === 'Core') {
        dispatch(reduxDataInsertAction_CORE(insertRecords));
      }

    }));
  };
}


/** ******************************************************
***** CALLING ACTIONS
* When calling for an action, the process flow is:
* 1. Call an action (this is the initial function)
*     This inital function would have the logic as
*     to what is to be done. Only when a redux state
*     change is required, it will call the redux function.
* 2. For Redux changes, call the update function
*     which is a redux update, change, remove with
*     a dot-path (etc) provided.
******************************************************* */

