import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Redirect } from 'react-router-dom';
import asyncComponent from "Helpers/AsyncFunc";
import classNames from 'classnames';

// CSS
import componentStyles from './styles.css';

// Components


//Actions
import { } from 'Actions';


class RouteLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  
  
  render() {
    const { exact, key, businessAccountRoute, userRoutePermissions } = this.props;

    console.log(`====== Route Loading ======`);

    return (
      <div className={classNames({ pageWrapper: true })}>
        <div className={classNames({ pad20: true, flex: true, flexDirectionColumn: true, flexAlignSelfCenter: true })}>

          <div className={classNames({ text_s36: true, padT40: true })}>
            Loading ...
          </div>

        </div>
      </div>
    );
      
  }
}


const mapStateToProps = (state, ownProps) => {

  return {
    
  };

};


const mapDispatchToProps = {
 
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RouteLoading);
