/** AUTH TOKENS ** */


//let firebaseAccessToken = '';

module.exports = {
  getGpAccessToken: () => global.gpAccessToken,
  setGpAccessToken: (accessToken) => {
    global.gpAccessToken = accessToken;
  },
  getGpRefreshToken: () => global.gpRefreshToken,
  setGpRefreshToken: (refreshToken) => {
    global.gpRefreshToken = refreshToken;
  },
}