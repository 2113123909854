/** BUSINESS IDENTITY ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';


//import AuthTokens from './AuthTokens';





import BusinessApi from './api/BusinessApi';




import BusinessIdentityApi from './api/BusinessIdentityApi';
import BusinessVerificationApi from './api/BusinessVerificationApi';

const uuidv1 = require('uuid/v1');




export const authStatusFalse_BUSINESS_IDENTITY = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_BUSINESS_IDENTITY = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});


export const syncBusinessData = businesses => ({
  type: 'SYNC_BUSINESS_DATA',
  businesses,
});

export const AddNewBusiness = businesses => ({
  type: 'ADD_NEW_BUSINESS',
  businesses,
});

export const setBusinessCommunicationsToken = data => ({
  type: 'SET_BUSINESS_COMMUNICATIONS_TOKEN',
  data,
});
export const setIdentityManagementVerificationCodeWebsite = data => ({
  type: 'SET_IDENTITY_MANAGEMENT_VERIFICATION_CODE_WEBSITE',
  data,
});





export const businessIdentitiesVerificationCodeInputError = data => ({
  type: 'BUSINESS_IDENTITIES_VERIFICATION_CODE_INPUT_ERROR',
  data,
});


export const updateBusinessIdentitiesItemVerificationToken = data => ({
  type: 'SET_BUSINESS_IDENTITIES_VERIFICATION_TOKEN',
  data,
});

export const setBusinessVerification_tokenWebsite = businessVerify_tokenWebsite => ({
  type: 'SET_BUSINESS_VERIFICATION_TOKEN_WEBSITE',
  businessVerify_tokenWebsite,
});

export const setConnectActiveProfile = data => ({
  type: 'SET_CONNECT_ACTIVE_PROFILE',
  data,
});



export const updateConnectLocalInteractionStatus = data => ({
  type: 'UPDATE_BUSINESS_IDENTITIES_LOCAL_INTERACTIONS',
  data,
});


export const cancelBusinessVerificationRedux = data => ({
  type: 'CANCEL_BUSINESS_VERIFICATION',
  data,
});



/** NEW CODE */
/*
refreshBusinessData        ()

addBusinessProfile      (businessId, accessToken)
removeBusinessProfile   (businessId, profileId, body, accessToken)
changeBusinessProfile   (businessId, profileId, body, accessToken)

addBusinessIdentityAssociation      (businessId, profileId, identityId, accessToken)
removeBusinessIdentityAssociation   (businessId, profileId, identityId, accessToken)

addBusinessIdentity       (businessId, accessToken)
removeBusinessIdentity    (businessId, identityId, body, accessToken)
changeBusinessIdentity    (businessId, identityId, body, accessToken)

*/




export function cancelBusinessVerification(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      const { data } = body;

      dispatch(cancelBusinessVerificationRedux(data));
      resolve(true);
      
    }));
  };
}








export function syncBusiness(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { data } = body;

      const businessId = data.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      const apiBody = JSON.stringify({
        businessId,
        businessDataRegion,
      });

      //console.log('apiActivityId');
      //console.log(apiActivityId);


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'syncBusiness',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.syncBusinessData(businessDataRegion, businessId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('Return | API | syncBusinessData');
          //console.log(apiResponseData);
          
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

export function refreshBusinessData(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      console.log("====== refreshBusinessData =========");
      console.log("====== attemptToGetAccessTokenFromMemory =========");
      //console.log(inMemoryJwtAccessToken);
      //Returns "undefined"
      //With import returned 'aaaa' being inital value

      //import { inMemoryJwtAccessToken } from './Auth';
      console.log("====== Local import within the function =========");
      //console.log(inMemoryJwtAccessToken);



      /*
      let AuthTokens = require('./AuthTokens');
      console.log('read getGpAccessToken | refreshBusinessData');
      console.log(AuthTokens.getGpAccessToken());
      */


      console.log("global.gpAccessToken");
      console.log(global.gpAccessToken);


      

      /*
      console.log("secret | refreshBusinessData");
      console.log(secret);


      
      
      console.log('read accessToken | refreshBusinessData');
      console.log(accessTokenManager.getAccessToken());
      //accessTokenManager is not defined
      */

      

      
      console.log("body data");
      console.log(body);
      console.log(JSON.stringify(body));

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      const apiBody = JSON.stringify({
        businessId,
        businessDataRegion,
      });

      //console.log('apiActivityId');
      //console.log(apiActivityId);


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'syncBusinessData',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.syncBusinessData(businessDataRegion, businessId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('Return | API | syncBusinessData');
          //console.log(apiResponseData);
          
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}



export function addBusinessContactIdentityToAccount(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      const identityId = business.identityId;
      const identityType = business.identityType;
      const identityContactType = business.identityContactType;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      const apiBody = JSON.stringify({
        businessId,
        identityId,
        identityType,
        identityContactType,
        businessDataRegion,
      });

      //console.log('apiActivityId');
      //console.log(apiActivityId);


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessContactIdentityToAccount(businessId, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('Return | API | createBusinessContactIdentityToAccount');
          //console.log(apiResponseData);
          
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

export function removeBusinessContactIdentityFromAccount(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      console.log("body | removeBusinessContactIdentityFromAccount");
      console.log(body);

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }



      const identityId = business.identityId;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }

      const apiBody = JSON.stringify({
        businessId,
        identityId,
        businessDataRegion,
      });

      console.log('apiBody');
      console.log(apiBody);

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.deleteBusinessContactIdentityFromAccount(businessDataRegion, businessId, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          //console.log('Return | API | createBusinessContactIdentityToAccount');
          if (apiResponseData.status === true) {
            
            
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


export function addBusinessIdentityAssociation(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      const profileId = business.profileId;
      const identityId = business.identityId;

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        businessDataRegion,
      });


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessIdentityAssociationToProfile(businessDataRegion, businessId, profileId, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createBusinessIdentityAssociationToProfile");
          if (apiResponseData.status === true) {

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

export function removeBusinessIdentityAssociation(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      const profileId = business.profileId;
      const identityId = business.identityId; // NOTE: this is the record id (not the identity id when removing the item)

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        businessDataRegion,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.deleteBusinessIdentityAssociationToProfile(businessDataRegion, businessId, profileId, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | deleteBusinessIdentityAssociationToProfile");
 
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

/**********************************
 * USED IN
 * - BusinessSettingsProfilesPage
 */
export function addBusinessProfile(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        businessDataRegion,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessProfile(businessDataRegion, businessId, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | createBusinessProfile");

        if (apiResponseData.status === true) {
          dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

          ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);
      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}

/*************************************
 * USED IN
 * - BusinessSettingsProfilesPage
 */
export function removeBusinessProfile(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      const profileId = business.profileId;


      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        businessDataRegion,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.deleteBusinessProfile(businessDataRegion, businessId, profileId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | deleteBusinessProfile");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


export function changeBusinessProfile(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      const profileId = business.profileId;


      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        value: 'valueToUpdate',
        businessDataRegion,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.updateBusinessProfile(businessDataRegion, businessId, profileId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | updateBusinessProfile");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}









/**************************************
 * USED IN
 * - BusinessSettingsIdentitiesPage
 */
export function addBusinessIdentity(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;
      const type = business.type;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        type,
        businessDataRegion, 
      });


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessIdentity(businessDataRegion, businessId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createBusinessIdentity");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

/******************************************
 * USED IN
 * - BusinessSettingsIdentitiesPage
 */
export function removeBusinessIdentity(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;
      const identityId = business.identityId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);


      const apiBody = JSON.stringify({
        businessDataRegion, 
      });


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.deleteBusinessIdentity(businessDataRegion, businessId, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | deleteBusinessIdentity");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

/*****************************************
 * USED IN
 * - BusinessSettingsIdentitiesPage
 */
export function changeBusinessIdentity(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;
      const identityId = business.identityId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        value: 'valueToUpdate',
        businessDataRegion, 
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.updateBusinessIdentity(businessDataRegion, businessId, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | updateBusinessIdentity");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}











export function businessIdentitiesVerifyNow(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);
      
      const {
        data = {},
      } = body;
      const businessIdentity = data.identity; // Whole identity object.
      const businessId = data.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      //console.log('businessIdentity');
      //console.log(businessIdentity);

      const businessIdentitiesId = businessIdentity._id; // id of identity
      const businessIdentitiesType = businessIdentity.type; // email



      const apiBody = JSON.stringify({
        businessId,
        businessIdentitiesId,
        businessIdentitiesType,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessIdentityVerificationRequest(businessId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createBusinessIdentityVerificationRequest");

          if (apiResponseData.status === true) {
            dispatch(setBusinessCommunicationsToken(apiResponseData.data)); // NEW SYNC TRIAL

            let websiteVerificationCode = '';
            try {
              if (apiResponseData.data.businessIdentitiesType === "website") {
                websiteVerificationCode = apiResponseData.data.websiteVerificationCode;
                dispatch(setIdentityManagementVerificationCodeWebsite(apiResponseData.data));
              }
            } catch (e) {}

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

export function businessIdentitiesCodeVerifyNow(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('data');
      //console.log(data);

      const businessIdentity = data.identity; // Whole identity object.
      const verificationCode = data.verificationCode; // Whole identity object.
      const businessId = data.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      //console.log('businessIdentity');
      //console.log(businessIdentity);

      const businessIdentitiesId = businessIdentity._id; // id of identity
      const businessIdentitiesType = businessIdentity.type; // email


      const apiBody = JSON.stringify({
        businessId,
        businessIdentitiesId,
        businessIdentitiesType,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessIdentityVerificationConfirmation(businessDataRegion, businessId, apiBody, businessIdentitiesId, verificationCode)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createBusinessIdentityVerificationConfirmation");
          if (apiResponseData.status === true) {

            //This api will not provide data back. 
            //Call another api to sync the data
            /* 
            let business = {
              businessId: businessId,
            }
            let apiUi = {
              apiActivityId: 'refreshBusinessData',
            }
            */

            //console.warn('refreshing business data');
            //dispatch(refreshBusinessData({business, apiUi}));
            
            //dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          
          try {
            if (apiResponseData.status === true) {
              if (apiResponseData.data.appStatus === true) {
                resolve({
                  status: true,
                });
              } else {
                resolve({
                  status: false,
                  message: apiResponseData.data.appMessageFailure,
                });
              }
            } else {
              resolve({
                status: false,
                message: 'We have experienced an error.',
              });
            }
          } catch (e) {
            console.error('Error while trying to return status from api call.');
          }

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

/**************************************************************************************
 * HANDLE VERIFICATION REQUEST FOR WEBSITE
 *************************************************************************************/

 
export function businessIdentitiesVerificationActivity_website(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('data');
      //console.log(data);

      const identity = data.identity; // Whole identity object.
      const businessId = data.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      //console.log('identity');
      //console.log(identity);

      const businessIdentitiesId = identity._id; // id of identity
      const businessIdentitiesType = identity.type; // email


      const apiBody = JSON.stringify({
        businessId,
        businessIdentitiesId,
        businessIdentitiesType,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessIdentityVerificationConfirmationRemote(businessId, apiBody, businessIdentitiesId)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createBusinessIdentityVerificationConfirmationRemote");
          if (apiResponseData.status === true) {
  
            //Website verification does return data as its an Auth Route
            /*
            let business = {
              businessId: businessId,
            }
            let apiUi = {
              apiActivityId: 'refreshBusinessData',
            }

            console.warn('refreshing business data');
            dispatch(refreshBusinessData({business, apiUi}));
            */


            

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              dispatch(syncBusinessData(apiResponseData.data.businessResult));
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
   
          try {
            if (apiResponseData.status === true) {
              if (apiResponseData.data.appStatus === true) {
                if (apiResponseData.data.verificationStatus === true) {
                  resolve({
                    status: true,
                  });
                } else {
                  resolve({
                    status: false,
                    message: 'Incorrect verification code on website.',
                  });
                }
              } else {
                resolve({
                  status: false,
                  message: apiResponseData.data.appMessageFailure,
                });
              }
            } else {
              resolve({
                status: false,
                message: 'We have experienced an error.',
              });
            }
          } catch (e) {
            console.error('Error while trying to return status from api call.');
          }




          
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}






































/*
export function businessIdentitiesVerifyNow(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const {
        data = {},
      } = body;
      const businessId = data.businessId; // we pass this through on the object as well
      //console.log('data');
      //console.log(data);

      //console.log('apiAccessToken');
      //console.log(apiAccessToken);

      console.table([
        {
          name: 'api',
          value: 'BusinessVerificationApi',
        },
        {
          name: 'function',
          value: 'businessIdentitiesVerify',
        },
        {
          name: 'AccessToken',
          value: apiAccessToken,
        },
      ]);

      const apiBody = JSON.stringify(data);

      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessVerificationApi.businessIdentitiesVerify(businessId, apiBody, apiAccessToken)
        .then((result) => {
          //console.log('businessIdentitiesVerify: Sync Business');
          //console.log(result);
          dispatch(syncBusinessData(result)); // Returns the business object (sync/merge it all)


          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(result);
        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}
*/
/*
export function generateVerificationTokenWebsite(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const {
        data = {},
      } = body;
      const businessId = data.businessId;
      const businessIdentitiesId = data.businessIdentitiesId;

      //console.log('data');
      //console.log(data);

      //console.log('apiAccessToken');
      //console.log(apiAccessToken);


      const apiBody = JSON.stringify(data);
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessVerificationApi.generateVerificationTokenWebsite(businessId, apiBody, apiAccessToken)
        .then((result) => {

          // dispatch(setBusinessVerification_tokenWebsite(result.token));     //old





          const reduxUpdateId = {
            id: businessId,
            arrItemId: businessIdentitiesId,
          };
          const reduxUpdateStatement = { verificationToken: { $set: result.token } };
          const data = {
            reduxUpdateId,
            reduxUpdateStatement,
          };
          dispatch(updateBusinessIdentitiesItemVerificationToken(data)); // new

          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(result);
        })
        .catch((error) => {
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}
*/

/* UNSURE IF THIS IS USED???? 12/01/2020
export const setStateBusinessIdentity = identities => ({
  type: 'BUSINESS_IDENTITY_SET',
  identities,
});
export const addUserManagedBusiness = user => ({
  type: 'SET_USER_MANAGED_BUSINESS',
  user,
});
*/












// User making changes to business identities
export function updateBusinessIdentitiesItem(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      //console.log('apiAccessToken');
      //console.log(apiAccessToken);

      const { data } = body;

      // dispatch(updateStateBusinessIdentities(data.index, data.state));
      dispatch(updateStateBusinessIdentities(reduxUpdateId, reduxUpdateStatement));

      
    }));
  };
}


/***************************************************
 * UPDATE A BUSINESS IDENTITY (THE PRIMARY VALUE)
 ***************************************************/
export function updateBusinessIdentities(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) { }

      const businessId = data.businessId;
  
      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      let type = '';
      try {
        type = data.type;
      } catch (e) {
        type = '';
      }
      if (type === undefined) {
        type = '';
      }
      let identity = '';
      try {
        identity = data.identity;
      } catch (e) {
        identity = '';
      }
      if (identity === undefined) {
        identity = '';
      }
      let identityId = '';
      try {
        identityId = data.identityId;
      } catch (e) {
        identityId = '';
      }
      if (identityId === undefined) {
        identityId = '';
      }
      
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        type,
        businessId,
        businessDataRegion,
        identity,
        identityId,
      });

      
      console.log('apiBody');
      console.log(apiBody);

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'updateBusinessIdentities',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.updateBusinessIdentity(businessDataRegion, businessId, identityId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          let toastData = {};
          console.warn("API | RESPONSE | updateBusinessIdentity");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL
              
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
      
    }));
  };
}

////////////////////////////////
// CREATE BUSINESS
////////////////////////////////
/*******************************
 * USED IN
 * - AccountBusinessPage
 */
export function createBusiness(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      let businessDataRegion = '';
      try {
        const { data } = body;
        businessDataRegion = data.businessDataRegion;
      } catch (e) {}



      const apiBody = JSON.stringify({
        businessDataRegion
      });

      
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusiness',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusiness(businessDataRegion, apiBody, apiAccessToken)
      .then((apiResponseData) => {
        //console.log('Return | API | createBusiness');
        let toastData = '';
        if (apiResponseData.status === true) {
          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            dispatch(AddNewBusiness(apiResponseData.data.businessResult));
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }
              dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_BUSINESS_IDENTITY());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              toastData = {
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }
              dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
            }
          }
          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
           // Toast - error experienced
           dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
      })
      .catch((error) => {
        dispatch(ApiUI.updateUIApiActivity({
          id: apiActivityId,
          status: 'failure',
        }));
        // Toast - error experienced
        dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
          id: uuidv1(),
          message: 'API error experienced', 
          type: 'error',
        }));
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}

/***************************************
 * USED IN
 * - BusinessAccountProfilePage
 * 
 */
export function deleteBusiness(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      let businessId = '';
      try {
        const {
          business,
        } = body;
        businessId = business.businessId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      let businessAccountRemovalAgreementAcceptance1 = false;
      let businessAccountRemovalAgreementAcceptance2 = false;
      let businessAccountRemovalAgreementAcceptance3 = false;
      try {
        let {
          acceptance
        } = body;
        businessAccountRemovalAgreementAcceptance1 = acceptance.removalAgreementAcceptance1;
        businessAccountRemovalAgreementAcceptance2 = acceptance.removalAgreementAcceptance2;
        businessAccountRemovalAgreementAcceptance3 = acceptance.removalAgreementAcceptance3;
      } catch (e) {}



 
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        businessAccountRemovalAgreementAcceptance1,
        businessAccountRemovalAgreementAcceptance2,
        businessAccountRemovalAgreementAcceptance3,
        businessDataRegion,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.deleteBusiness(businessId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          //console.log('Return | API | deleteBusiness');
          let toastData = '';
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              } else {
                console.log(`appMessageFailure is set: ${apiResponseData.data.appMessageFailure}`);
              }
              
              /*
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
              }
              */
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


/***************************************
 * USED IN
 * - BusinessAccountProfilePage
 * 
 */
export function requestImmediateBusinessDeletion(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      let businessId = '';
      try {
        const {
          business,
        } = body;
        businessId = business.businessId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }


      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        businessId,
        businessDataRegion,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'requestImmediateBusinessDeletion',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.requestImmediateBusinessDeletion(businessId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          //console.log('Return | API | requestImmediateBusinessDeletion');
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}











































/* I THINK THIS ONE IS WRONG - MIXED NAMING */
/*
export function createBusinessProfile(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      let businessId = '';
      try {
        const { business } = body;
        businessId = business.businessId;
      } catch (e) {}
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      //console.log('apiActivityId');
      //console.log(apiActivityId);


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'BusinessApi',
        function: 'createBusinessContactIdentityToAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessProfile(businessId, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createBusinessProfile");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(AddNewBusiness(apiResponseData.data.businessResult));
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });

    }));
  };
}
*/





/* UNSURE IF THIS IS USED???? 12/01/2020
export const updateStateBusinessIdentityProfile = (reduxUpdateId, reduxUpdateStatement) => ({
  type: 'BUSINESS_IDENTITY_PROFILE_SET',
  data: { reduxUpdateId, reduxUpdateStatement },
});


export function updateBusinessIdentityProfileReduxOnly(body = {}) {
  return dispatch => new Promise(((resolve, reject) => {

    const {
      reduxUpdateId = {}, reduxUpdateStatement = {},
    } = body;
    //console.log(reduxUpdateId);
    //console.log(reduxUpdateStatement);

    dispatch(updateStateBusinessIdentityProfile(reduxUpdateId, reduxUpdateStatement));

  }));
}
*/


/* UNSURE IF THIS IS USED???? 12/01/2020
export function updateBusinessIdentityProfile(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const {
        mongoFilterCondition = {}, mongoUpdateStatement = {},
        reduxUpdateId = {}, reduxUpdateStatement = {},
      } = body;

      // Print table to console.
      console.table([
        {
          name: 'MFilter',
          value: JSON.stringify(mongoFilterCondition),
        },
        {
          name: 'MUpdate',
          value: JSON.stringify(mongoUpdateStatement),
        },
        {
          name: 'RFilter',
          value: JSON.stringify(reduxUpdateId),
        },
        {
          name: 'RUpdate',
          value: JSON.stringify(reduxUpdateStatement),
        },
      ]);

      const apiBody = JSON.stringify({
        mongoFilterCondition,
        mongoUpdateStatement,
      });

      const businessId = mongoFilterCondition._id;
      //console.log(`businessId: ${businessId}`);

      if (businessId !== undefined) {

        console.table([
          {
            name: 'api',
            value: 'BusinessIdentityApi',
          },
          {
            name: 'function',
            value: 'updateBusinessIdentity',
          },
          {
            name: 'businessId',
            value: businessId,
          },
          {
            name: 'body',
            value: JSON.stringify(apiBody),
          },
          {
            name: 'AccessToken',
            value: apiAccessToken,
          },
        ]);
        // //////// API TRACKING /////////
        // Used for tracking the progress of
        // API calls and reporting faults.
        const apiMonitoring = {
          id: uuidv1(),
          api: 'BusinessApi',
          function: 'createBusinessContactIdentityToAccount',
          timestamp: Date.now(),
        };
        dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
        BusinessIdentityApi.updateBusinessIdentity(businessId, apiBody, apiAccessToken)
          .then((result) => {
            //console.log('return done from updateBusinessIdentity api');
            //console.log(result);

            dispatch(syncBusinessData(result.syncBusiness)); // NEW SYNC TRIAL
            dispatch(updateStateBusinessIdentityProfile(reduxUpdateId, reduxUpdateStatement));

            dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
            resolve(result);
          })
          .catch((error) => {
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
            reject(error);
          });

      }
    }));
  };
}
*/


/* UNSURE IF THIS IS USED???? 12/01/2020
export const replaceStateBusinessIdentityRegistry = (reduxUpdateId, reduxUpdateStatement) => ({
  type: 'BUSINESS_IDENTITY_REGISTRY_REPLACE',
  data: { reduxUpdateId, reduxUpdateStatement },
});
*/
/* UNSURE IF THIS IS USED???? 12/01/2020
export function addBusinessIdentityRegistry(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const {
        mongoFilterCondition = {}, mongoUpdateStatement = {},
        reduxUpdateId = {},
      } = body;
      let {
        reduxUpdateStatement = {},
      } = body;

      // Print table to console.
      console.table([
        {
          name: 'MFilter',
          value: JSON.stringify(mongoFilterCondition),
        },
        {
          name: 'MUpdate',
          value: JSON.stringify(mongoUpdateStatement),
        },
        {
          name: 'RFilter',
          value: JSON.stringify(reduxUpdateId),
        },
        {
          name: 'RUpdate',
          value: JSON.stringify(reduxUpdateStatement),
        },
      ]);

      const apiBody = JSON.stringify({
        mongoFilterCondition,
        mongoUpdateStatement,
      });

      const businessId = mongoFilterCondition._id;
      //console.log(`businessId: ${businessId}`);

      if (businessId !== undefined) {

        console.table([
          {
            name: 'api',
            value: 'BusinessIdentityApi',
          },
          {
            name: 'function',
            value: 'updateBusinessIdentity',
          },
          {
            name: 'businessId',
            value: businessId,
          },
          {
            name: 'body',
            value: JSON.stringify(apiBody),
          },
          {
            name: 'AccessToken',
            value: apiAccessToken,
          },
        ]);
        // //////// API TRACKING /////////
        // Used for tracking the progress of
        // API calls and reporting faults.
        const apiMonitoring = {
          id: uuidv1(),
          api: 'BusinessApi',
          function: 'createBusinessContactIdentityToAccount',
          timestamp: Date.now(),
        };
        dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
        BusinessIdentityApi.updateBusinessIdentity(businessId, apiBody, apiAccessToken)
          .then((result) => {
            //console.log('return done from updateBusinessIdentity api');
            //console.log(result);

            // Replace all state
            reduxUpdateStatement = {
              businessRegistry: { $set: result.dataBusiness.businessRegistry },
            };


            dispatch(replaceStateBusinessIdentityRegistry(reduxUpdateId, reduxUpdateStatement));
            dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
            resolve(result);
          })
          .catch((error) => {
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
            reject(error);
          });


      }
    }));
  };
}


export const updateStateBusinessIdentityRegistry = (reduxUpdateId, reduxUpdateStatement) => ({
  type: 'BUSINESS_IDENTITY_REGISTRY_UPDATE',
  data: { reduxUpdateId, reduxUpdateStatement },
});
*/

/* UNSURE IF THIS IS USED???? 12/01/2020
export function updateBusinessIdentityRegistry(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const {
        mongoFilterCondition = {}, mongoUpdateStatement = {},
        reduxUpdateId = {},
      } = body;
      const {
        reduxUpdateStatement = {},
      } = body;

      // Print table to console.
      console.table([
        {
          name: 'MFilter',
          value: JSON.stringify(mongoFilterCondition),
        },
        {
          name: 'MUpdate',
          value: JSON.stringify(mongoUpdateStatement),
        },
        {
          name: 'RFilter',
          value: JSON.stringify(reduxUpdateId),
        },
        {
          name: 'RUpdate',
          value: JSON.stringify(reduxUpdateStatement),
        },
      ]);

      const apiBody = JSON.stringify({
        mongoFilterCondition,
        mongoUpdateStatement,
      });

      const businessId = mongoFilterCondition._id;
      //console.log(`businessId: ${businessId}`);

      if (businessId !== undefined) {

        console.table([
          {
            name: 'api',
            value: 'BusinessIdentityApi',
          },
          {
            name: 'function',
            value: 'updateBusinessIdentity',
          },
          {
            name: 'businessId',
            value: businessId,
          },
          {
            name: 'body',
            value: JSON.stringify(apiBody),
          },
          {
            name: 'AccessToken',
            value: apiAccessToken,
          },
        ]);
        // //////// API TRACKING /////////
        // Used for tracking the progress of
        // API calls and reporting faults.
        const apiMonitoring = {
          id: uuidv1(),
          api: 'BusinessApi',
          function: 'createBusinessContactIdentityToAccount',
          timestamp: Date.now(),
        };
        dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
        BusinessIdentityApi.updateBusinessIdentity(businessId, apiBody, apiAccessToken)
          .then((result) => {
            //console.log('return done from updateBusinessIdentity api');
            //console.log(result);

            dispatch(updateStateBusinessIdentityRegistry(reduxUpdateId, reduxUpdateStatement));
            dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
            resolve(result);
          })
          .catch((error) => {
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
            reject(error);
          });


      }
    }));
  };
}
*/
/* UNSURE IF THIS IS USED???? 12/01/2020
export function removeBusinessIdentityRegistry(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const {
        mongoFilterCondition = {}, mongoUpdateStatement = {},
        reduxUpdateId = {},
      } = body;
      let {
        reduxUpdateStatement = {},
      } = body;

      // Print table to console.
      console.table([
        {
          name: 'MFilter',
          value: JSON.stringify(mongoFilterCondition),
        },
        {
          name: 'MUpdate',
          value: JSON.stringify(mongoUpdateStatement),
        },
        {
          name: 'RFilter',
          value: JSON.stringify(reduxUpdateId),
        },
        {
          name: 'RUpdate',
          value: JSON.stringify(reduxUpdateStatement),
        },
      ]);

      const apiBody = JSON.stringify({
        mongoFilterCondition,
        mongoUpdateStatement,
      });

      const businessId = mongoFilterCondition._id;
      //console.log(`businessId: ${businessId}`);

      if (businessId !== undefined) {

        console.table([
          {
            name: 'api',
            value: 'BusinessIdentityApi',
          },
          {
            name: 'function',
            value: 'updateBusinessIdentity',
          },
          {
            name: 'businessId',
            value: businessId,
          },
          {
            name: 'body',
            value: JSON.stringify(apiBody),
          },
          {
            name: 'AccessToken',
            value: apiAccessToken,
          },
        ]);
        // //////// API TRACKING /////////
        // Used for tracking the progress of
        // API calls and reporting faults.
        const apiMonitoring = {
          id: uuidv1(),
          api: 'BusinessApi',
          function: 'createBusinessContactIdentityToAccount',
          timestamp: Date.now(),
        };
        dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
        BusinessIdentityApi.updateBusinessIdentity(businessId, apiBody, apiAccessToken)
          .then((result) => {

            //console.log('return done from updateBusinessIdentity api - removeBusinessIdentityRegistry');
            //console.log(result);

            reduxUpdateStatement = {
              businessRegistry: { $set: result.dataBusiness.businessRegistry },
            };


            dispatch(replaceStateBusinessIdentityRegistry(reduxUpdateId, reduxUpdateStatement));
            dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
            resolve(result);
          })
          .catch((error) => {
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
            reject(error);
          });


      }
    }));
  };
}
*/






/** *************************************
******** BUSINESS IDENTITIES ***********
************************************** */
/* UNSURE IF THIS IS USED???? 12/01/2020
export const replaceStateBusinessIdentities = (reduxUpdateId, reduxUpdateStatement) => ({
  type: 'BUSINESS_IDENTITIES_REPLACE',
  data: { reduxUpdateId, reduxUpdateStatement },
});
*/
/* UNSURE IF THIS IS USED???? 12/01/2020
export function addBusinessIdentities(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const {
        mongoFilterCondition = {}, mongoUpdateStatement = {},
        reduxUpdateId = {},
      } = body;
      const {
        reduxUpdateStatement = {},
      } = body;

      // Print table to console.
      console.table([
        {
          name: 'MFilter',
          value: JSON.stringify(mongoFilterCondition),
        },
        {
          name: 'MUpdate',
          value: JSON.stringify(mongoUpdateStatement),
        },
        {
          name: 'RFilter',
          value: JSON.stringify(reduxUpdateId),
        },
        {
          name: 'RUpdate',
          value: JSON.stringify(reduxUpdateStatement),
        },
      ]);

      const apiBody = JSON.stringify({
        mongoFilterCondition,
        mongoUpdateStatement,
      });

      const businessId = mongoFilterCondition._id;
      //console.log(`businessId: ${businessId}`);

      if (businessId !== undefined) {

        console.table([
          {
            name: 'api',
            value: 'BusinessIdentityApi',
          },
          {
            name: 'function',
            value: 'updateBusinessIdentity',
          },
          {
            name: 'businessId',
            value: businessId,
          },
          {
            name: 'body',
            value: JSON.stringify(apiBody),
          },
          {
            name: 'AccessToken',
            value: apiAccessToken,
          },
        ]);
        // //////// API TRACKING /////////
        // Used for tracking the progress of
        // API calls and reporting faults.
        const apiMonitoring = {
          id: uuidv1(),
          api: 'BusinessApi',
          function: 'createBusinessContactIdentityToAccount',
          timestamp: Date.now(),
        };
        dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
        BusinessIdentityApi.updateBusinessIdentity(businessId, apiBody, apiAccessToken)
          .then((apiResponseData) => {
            //console.log('Return | API | updateBusinessIdentity');
            //console.log(apiResponseData);
            
            if (apiResponseData.status === true) {
              dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL
  
              ///////////////////////////////
              // APP STATUS TOAST MESSAGING
              ///////////////////////////////
              if (apiResponseData.data.appStatus === true) {
                //Display a success Toast if configured
                if (apiResponseData.data.appMessageSuccess !== '') {
                  toastData = {
                    id: uuidv1(),
                    message: apiResponseData.data.appMessageSuccess, 
                    type: 'success',
                  }
                  dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
                }
              } else {
                //// AUTH CHECK ////
                try {
                  if (apiResponseData.data.authStatus === false) {
                    dispatch(authStatusFalse_BUSINESS_IDENTITY());
                  }
                } catch (e) {}
                //Display a failure Toast if configured
                if (apiResponseData.data.appMessageFailure !== '') {
                  toastData = {
                    id: uuidv1(),
                    message: apiResponseData.data.appMessageSuccess, 
                    type: 'success',
                  }
                  dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
                }
              }
  
              
              dispatch(ApiUI.updateUIApiActivity({
                id: apiActivityId,
                status: 'successful',
              }));
  
            } else {
              // Toast - error experienced
              dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                id: uuidv1(),
                message: 'Error experienced', 
                type: 'error',
              }));
  
              dispatch(ApiUI.updateUIApiActivity({
                id: apiActivityId,
                status: 'failure',
              }));
  
            }
            
            dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
            resolve(apiResponseData);
          })
          .catch((error) => {
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
            reject(error);
          });

      }
    }));
  };
}
*/
/* UNSURE IF THIS IS USED???? 12/01/2020
export const updateStateBusinessIdentities = (reduxUpdateId, reduxUpdateStatement) => ({
  type: 'BUSINESS_IDENTITIES_UPDATE',
  data: { reduxUpdateId, reduxUpdateStatement },
});


export const updateBusinessDataState = (reduxUpdateId, reduxUpdateStatement) => ({
  type: 'BUSINESS_DATA_UPDATE',
  data: { reduxUpdateId, reduxUpdateStatement },
});
*/


// New Common/Shared Outcome Action
/* UNSURE IF THIS IS USED???? 12/01/2020
export function updateBusinessIdentities(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      // Get passed data
      const {
        action = {},
        mongoFilterCondition = {}, mongoUpdateStatement = {},
        reduxUpdateId = {}, reduxUpdateStatement = {},
      } = body;

      // Print table to console.
      console.table([
        {
          name: 'action',
          value: JSON.stringify(action),
        },
        {
          name: 'MFilter',
          value: JSON.stringify(mongoFilterCondition),
        },
        {
          name: 'MUpdate',
          value: JSON.stringify(mongoUpdateStatement),
        },
        {
          name: 'RFilter',
          value: JSON.stringify(reduxUpdateId),
        },
        {
          name: 'RUpdate',
          value: JSON.stringify(reduxUpdateStatement),
        },
      ]);

      if (action.task === 'redux') {
        // Update Redux ONLY
        //console.log('update redux only.');
        // Dispatch and resolve.
        dispatch(updateStateBusinessIdentities(reduxUpdateId, reduxUpdateStatement));
        resolve();

      } else if (action.task === 'reduxApi') {
        // Update Redux & API Endpoint
        //console.log('update redux and the api endpoint.');

        const apiBody = JSON.stringify({
          mongoFilterCondition,
          mongoUpdateStatement,
        });
        const businessId = mongoFilterCondition._id;
        //console.log(`businessId: ${businessId}`);

        if (businessId !== undefined) {
          console.table([
            {
              name: 'api',
              value: 'BusinessIdentityApi',
            },
            {
              name: 'function',
              value: 'updateBusinessIdentity',
            },
            {
              name: 'businessId',
              value: businessId,
            },
            {
              name: 'body',
              value: JSON.stringify(apiBody),
            },
            {
              name: 'AccessToken',
              value: apiAccessToken,
            },
          ]);
          // //////// API TRACKING /////////
          // Used for tracking the progress of
          // API calls and reporting faults.
          const apiMonitoring = {
            id: uuidv1(),
            api: 'BusinessApi',
            function: 'createBusinessContactIdentityToAccount',
            timestamp: Date.now(),
          };
          dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
          BusinessIdentityApi.updateBusinessIdentity(businessId, apiBody, apiAccessToken)
            .then((result) => {

              //console.log('return done from updateBusinessIdentity api');
              //console.log(result);

              dispatch(updateStateBusinessIdentities(reduxUpdateId, reduxUpdateStatement));
              dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
              resolve(result);
            })
            .catch((error) => {
              dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
              dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
              reject(error);
            });
        }
      } // end action.task

    }));
  };
}
*/

/* 
UNSURE IF THIS IS USED???? 12/01/2020
export function removeBusinessIdentities(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      const {
        mongoFilterCondition = {}, mongoUpdateStatement = {},
        reduxUpdateId = {},
      } = body;
      let {
        reduxUpdateStatement = {},
      } = body;

      // Print table to console.
      console.table([
        {
          name: 'MFilter',
          value: JSON.stringify(mongoFilterCondition),
        },
        {
          name: 'MUpdate',
          value: JSON.stringify(mongoUpdateStatement),
        },
        {
          name: 'RFilter',
          value: JSON.stringify(reduxUpdateId),
        },
        {
          name: 'RUpdate',
          value: JSON.stringify(reduxUpdateStatement),
        },
      ]);

      const apiBody = JSON.stringify({
        mongoFilterCondition,
        mongoUpdateStatement,
      });

      const businessId = mongoFilterCondition._id;
      //console.log(`businessId: ${businessId}`);

      if (businessId !== undefined) {

        console.table([
          {
            name: 'api',
            value: 'BusinessIdentityApi',
          },
          {
            name: 'function',
            value: 'updateBusinessIdentity',
          },
          {
            name: 'businessId',
            value: businessId,
          },
          {
            name: 'body',
            value: JSON.stringify(apiBody),
          },
          {
            name: 'AccessToken',
            value: apiAccessToken,
          },
        ]);
        // //////// API TRACKING /////////
        // Used for tracking the progress of
        // API calls and reporting faults.
        const apiMonitoring = {
          id: uuidv1(),
          api: 'BusinessApi',
          function: 'createBusinessContactIdentityToAccount',
          timestamp: Date.now(),
        };
        dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
        BusinessIdentityApi.updateBusinessIdentity(businessId, apiBody, apiAccessToken)
          .then((result) => {


            //console.log('return done from updateBusinessIdentity api - removeBusinessIdentityRegistry');
            //console.log(result);
            dispatch(syncBusinessData(result.syncBusiness)); // NEW SYNC TRIAL

            // error here??
            reduxUpdateStatement = {
              businessIdentities: { $set: result.dataBusiness.businessIdentities },
            };

            dispatch(replaceStateBusinessIdentities(reduxUpdateId, reduxUpdateStatement));
            dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
            resolve(result);
          })
          .catch((error) => {
            dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
            dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
            reject(error);
          });


      }
    }));
  };
}
*/



// NEW business identity - website verification
export function businessIdentitiesGenerateVerificationCode_website(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      const identityObj = data.businessIdentity;        // Whole identity object.
      const identityId = identityObj._id;     // id of identity
      const identityType = identityObj.type;  // email/phone/website
      const businessId = data.businessId; 

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      
      const apiBody = JSON.stringify({
        identityId,
        identityType,
        businessId,
        businessDataRegion,
      });
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'addUserIdentities',
        timestamp: Date.now(),
      };

      //business/v1/identity/business/id/identity/id/verification/website/generate

      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createBusinessIdentityVerificationCode(businessId, identityId, identityType, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createBusinessIdentityVerificationCode");
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                toastData = {
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY(toastData));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });

    }));
  };
}






////////////////////////////////////////////////////
// VERIFY AN EMAIL IDENTITY
// This is used on an email, calls our domain with the 
// verification code and the identity id. 
// We then call the api endpoint for verification.
// Allows the user to get a clean html response from the
// website.
////////////////////////////////////////////////////

export function businessIdentityVerification(body = {}) {
  return (dispatch, getState) => {
    //This will be an unauthenticated verification. 
    //The request is from an email address, clicking on a link.
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}

      let businessId = '';
      try {
        businessId = data.businessId;
      } catch (e) {}

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }



      let identityId = '';
      try {
        identityId = data.identityId;
      } catch (e) {}
      let verificationCode = '';
      try {
        verificationCode = data.verificationCode;
      } catch (e) {}
      

 
      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'businessIdentityVerification',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.businessIdentitiesVerificationUnauthenticated(businessId, identityId, verificationCode)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | businessIdentityVerification");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          resolve(true);

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            //Display a success Toast if configured
            if (apiResponseData.data.appMessageSuccess !== '') {
              dispatch(addGlobalToastMessage_USER({
                id: uuidv1(),
                message: apiResponseData.data.appMessageSuccess, 
                type: 'success',
              }));
            }
          } else {
            //// AUTH CHECK ////
            try {
              if (apiResponseData.data.authStatus === false) {
                dispatch(authStatusFalse_BUSINESS_IDENTITY());
              }
            } catch (e) {}
            //Display a failure Toast if configured
            if (apiResponseData.data.appMessageFailure !== '') {
              dispatch(addGlobalToastMessage_USER({
                id: uuidv1(),
                message: apiResponseData.data.appMessageFailure, 
                type: 'error',
              }));
            }
          }
          
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'successful',
          }));

        } else {
          // Toast - error experienced
          dispatch(addGlobalToastMessage_USER({
            id: uuidv1(),
            message: 'Error experienced', 
            type: 'error',
          }));

          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));

        }
        
        dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
        resolve(apiResponseData);
      })
      .catch((error) => {
        dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
        dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
        reject(error);
      });
    }));
  };
}





export function createApiKey(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      let apiKeyType = '';
      try {
        const { data } = body;
        apiKeyType = data.apiKeyType;
      } catch (e) {

      }

      const apiBody = JSON.stringify({
        businessDataRegion,
        apiKeyType,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ApiKeyManagement',
        function: 'createApiKey',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createApiKey(businessDataRegion, businessId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createApiKey");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}

export function removeApiKey(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      let apiKeyType = '';
      try {
        const { data } = body;
        apiKeyType = data.apiKeyType;
      } catch (e) {}

      let keyId = '';
      try {
        const { data } = body;
        keyId = data.keyId;
      } catch (e) {}

      const apiBody = JSON.stringify({
        businessDataRegion,
        businessId,
        apiKeyType,
        keyId,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ApiKeyManagement',
        function: 'removeApiKey',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.removeApiKey(businessDataRegion, businessId, keyId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | removeApiKey");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


/*************************************************
 * CREATE AN API KEY ITEM (The actual key)
 *************************************************/
export function createApiKeyItem(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      let keyId = '';
      try {
        const { data } = body;
        keyId = data.keyId;
      } catch (e) {

      }


      const apiBody = JSON.stringify({
        businessDataRegion,
        businessId,
        keyId,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ApiKeyManagement',
        function: 'createApiKeyItem',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createApiKeyItem(businessDataRegion, businessId, keyId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createApiKey");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


/*************************************************
 * REMOVE AN API KEY ITEM (The actual key)
 *************************************************/
export function removeApiKeyItem(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      let keyId = '';
      try {
        const { data } = body;
        keyId = data.keyId;
      } catch (e) {

      }
      let keyItemId = '';
      try {
        const { data } = body;
        keyItemId = data.keyItemId;
      } catch (e) {

      }

      const apiBody = JSON.stringify({
        businessDataRegion,
        businessId,
        keyId,
        keyItemId,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ApiKeyManagement',
        function: 'removeApiKeyItem',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.removeApiKeyItem(businessDataRegion, businessId, keyId, keyItemId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createApiKey");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


/*************************************************
 * TOGGLE AN API KEY ITEM STATUS (The actual key)
 *************************************************/
export function toggleApiKeyItemStatus(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      let keyId = '';
      try {
        const { data } = body;
        keyId = data.keyId;
      } catch (e) {

      }
      let keyItemId = '';
      try {
        const { data } = body;
        keyItemId = data.keyItemId;
      } catch (e) {

      }

      let status = 'active';
      try {
        const { data } = body;
        status = data.status;
      } catch (e) {
        status = 'active';
      }
      if (status === undefined) {
        status = 'active';
      }


      const apiBody = JSON.stringify({
        businessDataRegion,
        businessId,
        keyId,
        keyItemId,
        status,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ApiKeyManagement',
        function: 'toggleApiKeyItemStatus',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress      
      BusinessApi.toggleApiKeyItemStatus(businessDataRegion, businessId, keyId, keyItemId, status, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createApiKey");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


/*************************************************
 * REVEAL THE API ITEM SECRET KEY (The actual key)
 *************************************************/
export function revealSecretKey(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      let keyId = '';
      try {
        const { data } = body;
        keyId = data.keyId;
      } catch (e) {

      }
      let keyItemId = '';
      try {
        const { data } = body;
        keyItemId = data.keyItemId;
      } catch (e) {

      }


      const apiBody = JSON.stringify({
        businessDataRegion,
        businessId,
        keyId,
        keyItemId,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ApiKeyManagement',
        function: 'revealSecretKey',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress      
      BusinessApi.revealSecretKey(businessDataRegion, businessId, keyId, keyItemId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | revealSecretKey");
          let apiSecretKey = '';
          let apiSecretKeyStatus = false;
          if (apiResponseData.status === true) {

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {

              apiSecretKey = apiResponseData.data.apiSecretKey;
              apiSecretKeyStatus = true;


              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve({
            status: apiSecretKeyStatus,
            apiSecretKey: apiSecretKey,
          });
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}





/*************************************************
 * CREATE AN API IP RESTRICTION
 *************************************************/
export function createApiIpRestriction(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      let keyId = '';
      try {
        const { data } = body;
        keyId = data.keyId;
      } catch (e) {

      }
      let httpIpRestrictions = '';
      try {
        const { data } = body;
        httpIpRestrictions = data.httpIpRestrictions;
      } catch (e) {

      }


      const apiBody = JSON.stringify({
        businessDataRegion,
        businessId,
        keyId,
        httpIpRestrictions,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ApiKeyManagement',
        function: 'createApiIpRestriction',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.createApiKeyIpRestriction(businessDataRegion, businessId, keyId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createApiKey");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


/*************************************************
 * REMOVE AN API IP RESTRICTION
 *************************************************/
export function removeApiIpRestriction(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      const { business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      
      let keyId = '';
      try {
        const { data } = body;
        keyId = data.keyId;
      } catch (e) {

      }
      let httpIpRestrictions = '';
      try {
        const { data } = body;
        httpIpRestrictions = data.httpIpRestrictions;
      } catch (e) {

      }


      const apiBody = JSON.stringify({
        businessDataRegion,
        businessId,
        keyId,
        httpIpRestrictions,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'ApiKeyManagement',
        function: 'createApiIpRestriction',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.removeApiKeyIpRestriction(businessDataRegion, businessId, keyId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | createApiKey");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}








/*************************************************
 * REQUEST BUSINESS TRIAL ACCOUNT
 *************************************************/
export function requestBusinessTrialAccount(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      const { data, business } = body;

      const businessId = business.businessId;

      //// FIND BUSINESS REGION
      const { identities } = getState().BusinessIdentities;
      let businessDataRegion = '';
      for (let x = 0; x < identities.businesses.length; x++) {
        if (identities.businesses[x]['_id'] === businessId) {
          businessDataRegion = identities.businesses[x].dataRegion;
        }
      }

      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      

      const apiBody = JSON.stringify({
        businessDataRegion,
        businessId,
        data,
      });

      console.log("apiBody");
      console.log(apiBody);


      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'business',
        function: 'requestBusinessTrialAccount',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      BusinessApi.requestBusinessTrialAccount(businessDataRegion, businessId, apiBody)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | requestBusinessTrialAccount");
          if (apiResponseData.status === true) {
            dispatch(syncBusinessData(apiResponseData.data.businessResult)); // NEW SYNC TRIAL

            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_BUSINESS_IDENTITY());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }

            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));

          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_BUSINESS_IDENTITY({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));

            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));

          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);
        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}