/** ADMIN WORK ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

import AdminWorkApi from './api/AdminWorkApi';

const uuidv1 = require('uuid/v1');

export const authStatusFalse_ADMIN_WORK = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});

export const addGlobalToastMessage_ADMIN_WORK = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

export const syncWorkQueueVerificationsData = data => ({
  type: 'SYNC_WORK_QUEUE_VERIFICATIONS',
  data,
});

export const storeWorkQueueVerificationsData = data => ({
  type: 'STORE_WORK_QUEUE_VERIFICATION_DATA',
  data,
});



export function syncWorkQueue(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        type = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {

      }
      //console.log('apiActivityId');
      //console.log(apiActivityId);

      const apiBody = JSON.stringify({
        userId: _id, // Not required, just info
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'syncWorkQueue',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AdminWorkApi.syncWorkQueue(apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('API | RESPONSE | syncWorkQueue');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            dispatch(syncWorkQueueVerificationsData(apiResponseData.data.adminWorkQueue)); // NEW SYNC TRIAL
  
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_ADMIN_WORK());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_ADMIN_WORK({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}




export function getWorkQueueItem(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      

      let workQueueItemId = '';
      try {
        workQueueItemId = data.id;
      } catch (e) {}
      

      const apiBody = JSON.stringify({
        workQueueItemId,
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'getWorkQueueItem',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AdminWorkApi.getWorkQueueItem(workQueueItemId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('API | RESPONSE | getWorkQueueItem');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              let data = {
                workQueueItemId: apiResponseData.data.workQueueItemId,
                workItemData: apiResponseData.data.workItemData,
                businessItemData: apiResponseData.data.businessItemData,
              }
              dispatch(storeWorkQueueVerificationsData(data)); 

              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_ADMIN_WORK());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_ADMIN_WORK({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}




export function saveWorkQueueItem(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      

      let workQueueItemId = '';
      try {
        workQueueItemId = data.workQueueItemId;
      } catch (e) {}
      

      const apiBody = JSON.stringify(data);

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'getWorkQueueItem',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AdminWorkApi.saveWorkQueueItem(workQueueItemId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('API | RESPONSE | getWorkQueueItem');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              let data = {
                workQueueItemId: apiResponseData.data.workQueueItemId,
                workItemData: apiResponseData.data.workItemData,
                businessItemData: apiResponseData.data.businessItemData,
              }
              dispatch(storeWorkQueueVerificationsData(data)); 

              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_ADMIN_WORK());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_ADMIN_WORK({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}




export function processWorkQueueItem(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      

      let workQueueItemId = '';
      try {
        workQueueItemId = data.workQueueItemId;
      } catch (e) {}
      

      const apiBody = JSON.stringify(data);

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'getWorkQueueItem',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AdminWorkApi.processWorkQueueItem(workQueueItemId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('API | RESPONSE | getWorkQueueItem');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              let data = {
                workQueueItemId: apiResponseData.data.workQueueItemId,
                workItemData: apiResponseData.data.workItemData,
                businessItemData: apiResponseData.data.businessItemData,
              }
              console.warn("--- NO DISPATCH YET ---");
              //dispatch(storeWorkQueueVerificationsData(data)); 

              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_ADMIN_WORK());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_ADMIN_WORK({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}


export function updateWorkQueueItemStatus(body = {}) {
  return (dispatch, getState) => {
    const { apiAccessToken } = getState().Auth;
    const { _id } = getState().User;
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      let apiActivityId = '';
      try {
        const { apiUi } = body;
        apiActivityId = apiUi.apiActivityId;
      } catch (e) {}
      

      let workQueueItemId = '';
      try {
        workQueueItemId = data.id;
      } catch (e) {}
      let workQueueItemStatus = '';
      try {
        workQueueItemStatus = data.status;
      } catch (e) {}
      

      const apiBody = JSON.stringify({
        workQueueItemId,
        workQueueItemStatus
      });

      // //////// API TRACKING /////////
      // Used for tracking the progress of
      // API calls and reporting faults.
      const apiMonitoring = {
        id: uuidv1(),
        api: 'UsersApi',
        function: 'updateWorkQueueItemStatus',
        timestamp: Date.now(),
      };
      dispatch(ApiMonitoring.updateApiInProgressCounter(apiMonitoring)); // API In Progress
      AdminWorkApi.statusUpdateWorkQueueItem(workQueueItemId, apiBody, apiAccessToken)
        .then((apiResponseData) => {
          console.warn('API | RESPONSE | statusUpdateWorkQueueItem');
          //console.log(apiResponseData);

          if (apiResponseData.status === true) {
            ///////////////////////////////
            // APP STATUS TOAST MESSAGING
            ///////////////////////////////
            if (apiResponseData.data.appStatus === true) {
              let data = {
                workQueueItemId: apiResponseData.data.workQueueItemId,
                workItemData: apiResponseData.data.workItemData,
                businessItemData: apiResponseData.data.businessItemData,
              }
              dispatch(storeWorkQueueVerificationsData(data)); 

              //Display a success Toast if configured
              if (apiResponseData.data.appMessageSuccess !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageSuccess, 
                  type: 'success',
                }));
              }
            } else {
              //// AUTH CHECK ////
              try {
                if (apiResponseData.data.authStatus === false) {
                  dispatch(authStatusFalse_ADMIN_WORK());
                }
              } catch (e) {}
              //Display a failure Toast if configured
              if (apiResponseData.data.appMessageFailure !== '') {
                dispatch(addGlobalToastMessage_ADMIN_WORK({
                  id: uuidv1(),
                  message: apiResponseData.data.appMessageFailure, 
                  type: 'error',
                }));
              }
            }
  
            
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'successful',
            }));
  
          } else {
            // Toast - error experienced
            dispatch(addGlobalToastMessage_ADMIN_WORK({
              id: uuidv1(),
              message: 'Error experienced', 
              type: 'error',
            }));
  
            dispatch(ApiUI.updateUIApiActivity({
              id: apiActivityId,
              status: 'failure',
            }));
  
          }
          
          dispatch(ApiMonitoring.updateApiSuccessCounter(apiMonitoring)); // API Successful
          resolve(apiResponseData);

        })
        .catch((error) => {
          dispatch(ApiUI.updateUIApiActivity({
            id: apiActivityId,
            status: 'failure',
          }));
          dispatch(ApiMonitoring.updateApiFailureCounter(apiMonitoring)); // API Failure
          dispatch(ApiMonitoring.updateApiStatusCodeCounter(error)); // API Status Codes
          reject(error);
        });
    }));
  };
}
