import Request from './Request';

class BusinessIdentityApi {
/* REMOVED OLD???
  static createNewBusinessIdentity(accessToken) {
    console.warn('API | Create new Business Identity');
    return Request.POST_json(`${process.env.businessAPI}/v1/business`, null, global.gpAccessToken);
  }
  */
  /*
  static addNewBusinessIdentity(accessToken) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business`, null, global.gpAccessToken);
  }
  */
  //Used in the BusinessIdentity (find out if they are old code refernces?)
  static updateBusinessIdentity(businessId, body) {
    console.warn('API | INVESTIGATE - WHEN IS THIS API CALLED???');
    return Request.POST_json(`${process.env.businessAPI}/v1/business/${businessId}`, body, global.gpAccessToken);
  }





}


export default BusinessIdentityApi;
